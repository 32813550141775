import * as Yup from 'yup';

import { useValidation } from '@/hooks/useValidation';
import { Shape } from '@/models';
import {
  FormCourseGroupModal,
  FormCourseModel,
  FormCourseVideoModal,
  FormLevelModal,
} from '@/modules/course/models/form';

const { stringRequired, numberRequired, fileUploadRequired, urlRequired } = useValidation('');

function getCourseFormSchema() {
  return Yup.object<Shape<FormCourseModel>>({
    description: stringRequired('Mô tả '),
    name: stringRequired('Tên '),
    price: numberRequired('Giá '),
    status: stringRequired('Trạng thái '),
    target_skills: stringRequired('Kĩ năng '),
    thumbnail: fileUploadRequired('thumbnail '),
  });
}

function getGroupVideoFormSchema() {
  return Yup.object<Shape<FormCourseGroupModal>>({
    name: stringRequired('Tên '),
  });
}

function getVideoFormSchema() {
  return Yup.object<Shape<FormCourseVideoModal>>({
    name: stringRequired('Tên '),
    total_time: stringRequired('Thời gian '),
    type: stringRequired('Kiểu '),
    video_url: urlRequired('Link video '),
  });
}

function getLevelFormSchema() {
  return Yup.object<Shape<FormLevelModal>>({
    name: stringRequired('Tên '),
  });
}

function getLabelFormSchema() {
  return Yup.object<Shape<FormLevelModal>>({
    name: stringRequired('Tên '),
  });
}

export {
  getCourseFormSchema,
  getGroupVideoFormSchema,
  getLabelFormSchema,
  getLevelFormSchema,
  getVideoFormSchema,
};
