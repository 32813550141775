import { isEmpty, isObject, isUndefined } from 'lodash-es';

import { FileUploadModel } from '@/models';

export const fileUploadModelToString = (file?: FileUploadModel): string => {
  return file?.image_url || '';
};

export const fileUploadModelsToStrings = (files?: FileUploadModel[]): string[] => {
  return files?.map((f) => f.image_url || '') || [];
};

export const stringToFileUploadModel = (url?: string): FileUploadModel => {
  return { image_url: url };
};

export const stringsToFileUploadModels = (urls?: string[]): FileUploadModel[] => {
  return urls?.map((u) => ({ image_url: u })) || [];
};

export const isFileUploadModel = (data?: any): data is FileUploadModel => {
  if (isEmpty(data) || !isObject(data)) return false;

  return !isUndefined((data as any).image_url) || !isUndefined((data as any).progress);
};
