import { isString } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { KTCard, KTCardBody } from '@/_metronic/helpers';
import { TableGlobalSearch } from '@/components/molecules/TableGlobalSearch';
import { Table } from '@/components/organisms/Table';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { useTable } from '@/hooks/useTable';
import { TagModal } from '@/modules/article/components/modal/TagModal';
import { getColumnDef } from '@/modules/article/components/tag-list/_columns';
import { useDeleteTag } from '@/modules/article/hooks/useTag/useDeleteTag';
import { useGetTagList } from '@/modules/article/hooks/useTag/useGetTagList';
import { TagFilterModel, TagModel } from '@/modules/article/models/response';
import { CourseListToolbar } from '@/modules/course/components/course-list/CourseListToolbar';

const TagList = () => {
  const [tagToEdit, setTagToEdit] = useState<TagModel>();
  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false);
  const {
    queryState,
    updateQueryState,
    updateSortStateByTable,
    getTableSortState,
    globalSearch,
    handleUpdateGlobalSearch,
  } = useQueryRequest<TagFilterModel>();

  const { ...tableProps } = useTable<TagModel>(getTableSortState);
  const columnDef = useMemo(
    () => getColumnDef(tableProps.columnResizeMode, handleClickEdit, handleClickRemove),
    []
  );

  const { isLoading, isFetching, data, refetch } = useGetTagList();
  const { mutateAsync: deleteTagById } = useDeleteTag();
  useEffect(() => {
    updateSortStateByTable(tableProps.sorting);
  }, [tableProps.sorting]);

  function handleClickEdit(tag: TagModel) {
    if (!isString(tag.id)) return;
    setTagToEdit(tag);
    setIsVisibleFormModal(true);
  }

  async function handleClickRemove(tag: TagModel) {
    const response = await deleteTagById(tag.id);
    response && refetch();
  }

  function handleSaved() {
    refetch();
    setIsVisibleFormModal(false);
  }

  return (
    <>
      <KTCard>
        <div className="card-header border-0 pt-6">
          <TableGlobalSearch onChange={handleUpdateGlobalSearch} value={globalSearch} />
          <div className="card-toolbar">
            {(() => (
              <CourseListToolbar
                onClickCreateButton={() => {
                  setTagToEdit(undefined);
                  setIsVisibleFormModal(true);
                }}
              />
            ))()}
          </div>
        </div>

        <KTCardBody className="py-4">
          <Table
            {...tableProps}
            className="cursor-pointer"
            columns={columnDef}
            currentPage={queryState.page}
            data={data}
            isLoading={isFetching || isLoading}
            limit={queryState.limit}
            onLimitChange={(limit) => updateQueryState({ limit })}
            onPageChange={(page) => updateQueryState({ page })}
          />
        </KTCardBody>
      </KTCard>

      {isVisibleFormModal && (
        <TagModal
          onHide={() => {
            setIsVisibleFormModal(false);
          }}
          onSaved={handleSaved}
          show={isVisibleFormModal}
          tag={tagToEdit}
        />
      )}
    </>
  );
};

const TagListWrapper = () => <TagList />;

export { TagListWrapper };
