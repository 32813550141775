import { isString, size } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { KTCard, KTCardBody } from '@/_metronic/helpers';
import { TableGlobalSearch } from '@/components/molecules/TableGlobalSearch';
import { TableListGrouping } from '@/components/molecules/TableListGrouping';
import { Table } from '@/components/organisms/Table';
import { useGetSkillList } from '@/hooks/useGetSkillList';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { useTable } from '@/hooks/useTable';
import { SkillModel } from '@/models/skill';
import { CourseListToolbar } from '@/modules/course/components/course-list/CourseListToolbar';
import { SkillModal } from '@/modules/course/components/modals/SkillModal';
import { getColumnDef } from '@/modules/course/components/skill-list/_columns';
import { useDeleteSkill } from '@/modules/course/hooks/skill/useDeleteSkill';
import { SkillFilterModel } from '@/modules/course/models';

const SkillList = () => {
  const [skillIdToEdit, setSkillIdToEdit] = useState('');
  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false);
  const {
    queryState,
    updateQueryState,
    updateSortStateByTable,
    getTableSortState,
    globalSearch,
    handleUpdateGlobalSearch,
  } = useQueryRequest<SkillFilterModel>();

  const { ...tableProps } = useTable<SkillModel>(getTableSortState);
  const columnDef = useMemo(
    () => getColumnDef(tableProps.columnResizeMode, handleClickEdit, handleClickRemove),
    []
  );

  const { data, isLoading, isFetching, refetch } = useGetSkillList();
  const { mutateAsync: deleteLevelById } = useDeleteSkill();
  useEffect(() => {
    updateSortStateByTable(tableProps.sorting);
  }, [tableProps.sorting]);

  function handleClickEdit(skill: SkillModel) {
    if (!isString(skill.id)) return;
    setSkillIdToEdit(skill.id);
    setIsVisibleFormModal(true);
  }

  async function handleClickRemove(skill: SkillModel) {
    const response = await deleteLevelById(skill.id);
    response && refetch();
  }

  function handleSaved() {
    refetch();
    setIsVisibleFormModal(false);
  }

  return (
    <>
      <KTCard>
        <div className="card-header border-0 pt-6">
          <TableGlobalSearch onChange={handleUpdateGlobalSearch} value={globalSearch} />
          <div className="card-toolbar">
            {(() => {
              if (size(tableProps.rowSelection) > 0) {
                return <TableListGrouping keyText={'name'} />;
              }

              return <CourseListToolbar onClickCreateButton={() => setIsVisibleFormModal(true)} />;
            })()}
          </div>
        </div>

        <KTCardBody className="py-4">
          <Table
            {...tableProps}
            className="cursor-pointer"
            columns={columnDef}
            currentPage={queryState.page}
            data={data}
            isLoading={isFetching || isLoading}
            limit={queryState.limit}
            onLimitChange={(limit) => updateQueryState({ limit })}
            onPageChange={(page) => updateQueryState({ page })}
          />
        </KTCardBody>
      </KTCard>

      {isVisibleFormModal && (
        <SkillModal
          onHide={() => {
            setIsVisibleFormModal(false);
            setSkillIdToEdit('');
          }}
          onSaved={handleSaved}
          show={isVisibleFormModal}
          skillId={skillIdToEdit}
        />
      )}
    </>
  );
};

const SkillListWrapper = () => <SkillList />;

export { SkillListWrapper };
