import { Button, Statistic, Tabs } from 'antd';
import React, { useState } from 'react';

import { InputDateRangeField } from '@/components/molecules/InputDateRangeField';
import { DateTimeFormat } from '@/constants';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { ChartMoneyUser } from '@/modules/report/component/ChartMoneyUser';
import { ChartVisiter } from '@/modules/report/component/ChartVisiter';
import { ChartFilterModel } from '@/modules/report/models/response';

const Overview: React.FC = () => {
  const [time, setTime] = useState(7);

  const { queryState, updateFiltersState } = useQueryRequest<ChartFilterModel>();

  const handleTabClick = (key: string) => {
    console.log(`Tab ${key} clicked`);
  };

  const seriesData = {
    dates: ['2022-01-01', '2022-01-02', '2022-01-03', '2022-01-04', '2022-01-05'],
    prices: [10, 20, 30, 40, 50],
  };

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body p-9 ">
        <>
          <div className="w-100  d-flex justify-content-end">
            <Button className="me-3" onClick={() => setTime(7)} size="large">
              7 ngày trước
            </Button>
            <Button className="me-3" onClick={() => setTime(28)} size="large">
              28 ngày trước
            </Button>
            <Button className="me-3" onClick={() => setTime(365)} size="large">
              365 ngày trước
            </Button>
            <InputDateRangeField
              format={DateTimeFormat.fe_date}
              formatValue={DateTimeFormat.fe_date}
              name="date_range"
              onChange={(values) =>
                updateFiltersState({ from: values?.[0] as string, to: values?.[1] as string })
              }
              value={[queryState.filters?.from || '', queryState.filters?.to || '']}
            />
          </div>
          <Tabs defaultActiveKey="1" onTabClick={handleTabClick}>
            <Tabs.TabPane
              key="1"
              tab={
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: '100px',
                    width: '200px',
                  }}
                >
                  <div>
                    <Statistic
                      className="text-center"
                      style={{ color: '#000', fontWeight: 'bold' }}
                      title={<div className="text-primary">Doanh thu</div>}
                      value={1000000}
                    />
                  </div>
                </div>
              }
            >
              <ChartMoneyUser time={time} />
            </Tabs.TabPane>
            <Tabs.TabPane
              key="2"
              tab={
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: '100px',
                    width: '200px',
                  }}
                >
                  <Statistic
                    className="text-center"
                    style={{ color: '#000', fontWeight: 'bold' }}
                    title={<div className="text-primary">Lượng truy cập</div>}
                    value={1231}
                  />
                </div>
              }
            >
              <ChartVisiter seriesData={seriesData} />
            </Tabs.TabPane>
          </Tabs>
        </>
      </div>
    </div>
  );
};

export { Overview };
