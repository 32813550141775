import { isString } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { KTCard, KTCardBody } from '@/_metronic/helpers';
import { SelectCourse } from '@/components/molecules/SelectCourse';
import { TableGlobalSearch } from '@/components/molecules/TableGlobalSearch';
import { Table } from '@/components/organisms/Table';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { useTable } from '@/hooks/useTable';
import { getColumnDef } from '@/modules/order/components/order-list/_columns';
import { OrderModal } from '@/modules/order/components/OrderModal';
import { useGetOrderList } from '@/modules/order/hooks/useGetOrderList';
import { OrderFilterModel } from '@/modules/order/models/filters';
import { OrderModel } from '@/modules/order/models/response';

const OrderList = () => {
  const [orderIdToEdit, setOrderIdToEdit] = useState('');
  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false);
  const {
    queryState,
    updateQueryState,
    updateSortStateByTable,
    getTableSortState,
    updateFiltersState,
    queryString,
    globalSearch,
    handleUpdateGlobalSearch,
  } = useQueryRequest<OrderFilterModel>();

  const { ...tableProps } = useTable<OrderModel>(getTableSortState);
  const columnDef = useMemo(() => getColumnDef(tableProps.columnResizeMode, onClickButtonText), []);

  const { isFetching, isLoading, refetch, data } = useGetOrderList(queryString);
  useEffect(() => {
    updateSortStateByTable(tableProps.sorting);
  }, [tableProps.sorting]);

  function onClickButtonText(order: OrderModel) {
    if (!isString(order.id)) return;
    setOrderIdToEdit(order.id);
    setIsVisibleFormModal(true);
  }

  function handleSaved() {
    refetch();
    setIsVisibleFormModal(false);
  }

  return (
    <div data-testid="employee-list-element">
      <KTCard>
        <div className="card-header border-0 pt-6">
          <TableGlobalSearch onChange={handleUpdateGlobalSearch} value={globalSearch} />
          <SelectCourse
            name="id"
            onChange={(e) => updateFiltersState({ course_id: e?.id || '' })}
            clearable
          />
        </div>

        <KTCardBody className="py-4">
          <Table
            {...tableProps}
            columns={columnDef}
            currentPage={queryState.page}
            data={data?.rows}
            isLoading={isFetching || isLoading}
            limit={queryState.limit}
            onLimitChange={(limit) => updateQueryState({ limit })}
            onPageChange={(page) => updateQueryState({ page })}
            total={data?.total_rows}
          />
        </KTCardBody>
      </KTCard>

      {isVisibleFormModal && (
        <OrderModal
          onHide={() => {
            setIsVisibleFormModal(false);
            setOrderIdToEdit('');
          }}
          onSaved={handleSaved}
          orderId={orderIdToEdit}
          show={isVisibleFormModal}
        />
      )}
    </div>
  );
};

const OrderListWrapper = () => <OrderList />;

export { OrderListWrapper };
