import { size } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import { KTCard, KTCardBody } from '@/_metronic/helpers';
import { TableGlobalSearch } from '@/components/molecules/TableGlobalSearch';
import { TableListGrouping } from '@/components/molecules/TableListGrouping';
import { Table } from '@/components/organisms/Table';
import { useGetCache } from '@/hooks/useGetCache';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { useTable } from '@/hooks/useTable';
import { TypeClear } from '@/models/clearCache';
import { getColumnDef } from '@/modules/course/components/course-list/_columns';
import { CourseListToolbar } from '@/modules/course/components/course-list/CourseListToolbar';
import { useDeleteCourse } from '@/modules/course/hooks/useDeleteCourse';
import { useGetCourseList } from '@/modules/course/hooks/useGetCourseList';
import { CourseFilterModel, CourseModel } from '@/modules/course/models';
import { history } from '@/utils';

const CourseList = () => {
  const {
    queryState,
    updateQueryState,
    updateSortStateByTable,
    getTableSortState,
    queryString,
    globalSearch,
    handleUpdateGlobalSearch,
  } = useQueryRequest<CourseFilterModel>();

  const { ...tableProps } = useTable<CourseModel>(getTableSortState);
  const columnDef = useMemo(
    () =>
      getColumnDef(
        tableProps.columnResizeMode,
        handleClickEdit,
        handleClickRemove,
        handleClickClear
      ),
    []
  );

  const { data, isLoading, isFetching, refetch } = useGetCourseList(queryString);
  const { mutateAsync: deleteCourseById } = useDeleteCourse();
  const { mutateAsync: clearCache } = useGetCache();
  useEffect(() => {
    updateSortStateByTable(tableProps.sorting);
  }, [tableProps.sorting]);

  function handleClickEdit(course: CourseModel) {
    history.push(`course/${course.id}/update`);
  }

  function handleClickClear(course: CourseModel) {
    clearCache({ slug: course.slug, type: TypeClear.course });
  }

  async function handleClickRemove(course: CourseModel) {
    const response = await deleteCourseById(course.id);
    response && refetch();
  }

  return (
    <KTCard>
      <div className="card-header border-0 pt-6">
        <TableGlobalSearch onChange={handleUpdateGlobalSearch} value={globalSearch} />
        <div className="card-toolbar">
          {(() => {
            if (size(tableProps.rowSelection) > 0) {
              return <TableListGrouping keyText={'name'} />;
            }

            return <CourseListToolbar onClickCreateButton={() => history.push(`/course/create`)} />;
          })()}
        </div>
      </div>

      <KTCardBody className="py-4">
        <Table
          {...tableProps}
          columns={columnDef}
          currentPage={queryState.page}
          data={data?.rows}
          isLoading={isFetching || isLoading}
          limit={queryState.limit}
          onLimitChange={(limit) => updateQueryState({ limit })}
          onPageChange={(page) => updateQueryState({ page })}
          total={data?.total_rows}
        />
      </KTCardBody>
    </KTCard>
  );
};

const CourseListWrapper = () => <CourseList />;

export { CourseListWrapper };
