import { ColumnDef, ColumnResizeMode, createColumnHelper } from '@tanstack/react-table';
import { Image } from 'antd';

import { TableActionCellFlatStyle } from '@/components/molecules/TableActionCellFlatStyle';
import { TableHeaderCell } from '@/components/molecules/TableHeaderCell';
import { TableTagCell } from '@/components/molecules/TableTagCell';
import { TableVisibilityColumnCell } from '@/components/molecules/TableVisibilityColumnCell';
import { OrderModel } from '@/modules/order/models/response';
import { getTimeFormat } from '@/utils';

function getColumnDef(
  columnResizeMode: ColumnResizeMode = 'onChange',
  onClickButtonText?: (data: OrderModel) => void
): ColumnDef<OrderModel, any>[] {
  const columnHelper = createColumnHelper<OrderModel>();

  return [
    columnHelper.accessor('course_thumbnail', {
      cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <Image
            className="rounded"
            preview={false}
            src={row.original.course_thumbnail}
            width={60}
          />
        </div>
      ),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-50px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="thumbnail"
        />
      ),
    }),

    columnHelper.accessor('full_name', {
      cell: ({ row }) => (
        <div className="d-flex flex-column">
          <span className="d-flex align-items-center mb-2">
            <Image preview={false} src="/media/icons/duotune/general/person_order.svg" width={15} />
            <span className="ms-2">{row.original.full_name}</span>
          </span>
          <span className="mb-2">
            <Image preview={false} src="/media/icons/duotune/general/mail_order.svg" width={15} />
            <span className="ms-2">{row.original.email}</span>
          </span>
          <span>
            <Image preview={false} src="/media/icons/duotune/general/phone_order.svg" width={15} />
            <span className="ms-2">{row.original.phone}</span>
          </span>
        </div>
      ),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-100px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="học viên"
        />
      ),
    }),

    columnHelper.accessor('course_name', {
      cell: ({ row }) => {
        return <span style={{ whiteSpace: 'break-spaces' }}>{row.original.course_name}</span>;
      },
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-90px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Khóa học"
        />
      ),
    }),

    columnHelper.accessor('status', {
      cell: ({ row }) => {
        const isActive = row.original.status;

        return (
          <TableTagCell
            className={isActive !== 'APPROVED' ? 'text-dark' : ''}
            color={isActive === 'APPROVED' ? 'success' : 'warning'}
          >
            {isActive}
          </TableTagCell>
        );
      },
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-50px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="trạng thái"
        />
      ),
    }),

    columnHelper.accessor('created_at', {
      cell: ({ row }) => getTimeFormat(row.original.created_at),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-90px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Ngày yêu cầu"
        />
      ),
    }),

    columnHelper.accessor('updated_at', {
      cell: ({ row }) => getTimeFormat(row.original.updated_at),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-90px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Ngày duyệt"
        />
      ),
    }),

    columnHelper.accessor((row) => row.id, {
      cell: ({ row }) => {
        const isApproved = row.original.status === 'APPROVED';

        return (
          <TableActionCellFlatStyle
            ButtonText={isApproved ? 'Đã xác nhận' : 'Duyệt đơn'}
            data={row.original}
            iconClassName="me-2"
            isShowEditButton={false}
            isShowRemoveButton={false}
            isShowViewButton={false}
            onClickButtonText={onClickButtonText}
            style={{ width: '120px' }}
            urlIcon={
              isApproved
                ? '/media/icons/duotune/general/check-while.svg'
                : '/media/icons/duotune/general/send.svg'
            }
            variant={isApproved ? 'success' : 'primary'}
            viewButtonClassName="p-2 text-white"
          />
        );
      },
      enableHiding: false,
      enableResizing: false,
      enableSorting: false,
      header: ({ header, table }) => (
        <TableHeaderCell className="w-80px" columnResizeMode={columnResizeMode} header={header}>
          <TableVisibilityColumnCell
            className="justify-content-end"
            menuClassName="text-primary"
            table={table}
            title={'Tác vụ'}
          />
        </TableHeaderCell>
      ),
      id: 'actions',
    }),
  ];
}

export { getColumnDef };
