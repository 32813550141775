import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputColorField } from '@/components/molecules/InputColorField';
import { InputField } from '@/components/molecules/InputField';
import { Modal } from '@/components/organisms/Modal';
import { useCreateTag } from '@/modules/article/hooks/useTag/useCreateTag';
import { useUpdateTag } from '@/modules/article/hooks/useTag/useUpdateTag';
import { FormTagModal } from '@/modules/article/models/form';
import { TagModel } from '@/modules/article/models/response';
import { getLabelFormSchema } from '@/modules/course/services/validation';

interface IProps {
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly show?: boolean;
  readonly tag?: TagModel;
}

const defaultValues: FormTagModal = {
  color: '',
  name: '',
};

const TagModal: FC<IProps> = ({ show, onHide, tag, onSaved }) => {
  const { mutateAsync: createTag } = useCreateTag();

  const { mutateAsync: updateTagById } = useUpdateTag();

  const resolver = yupResolver(getLabelFormSchema());
  const formMethods = useForm<FormTagModal>({ defaultValues, resolver });
  const action = tag
    ? (formData: FormTagModal) => updateTagById({ id: tag.id, ...formData })
    : createTag;
  const onSubmit: SubmitHandler<FormTagModal> = async (formData) => {
    const res = await action({ ...formData, color: formData.color });
    if (!res) return;
    onSaved?.();
    onHide?.();
    formMethods.reset();
  };

  useEffect(() => {
    formMethods.reset({
      color: tag?.color,
      name: tag?.name,
    });
  }, [tag]);

  function handleHide() {
    onHide?.();
    formMethods.reset();
  }

  return (
    <Modal
      backdrop={'static'}
      bodyClassName="scroll-y"
      dialogClassName="mw-850px"
      onHide={handleHide}
      show={show}
      title={<h2 className="fw-bolder">{tag ? 'Chỉnh sửa' : 'Tạo'} nhãn</h2>}
      centered
    >
      <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
        <InputField
          autoComplete={false}
          control={formMethods.control}
          groupClass="mb-7 fv-row"
          label={<b>Tag</b>}
          name="name"
          required
        />

        <InputColorField
          control={formMethods.control}
          label={<b>Màu nền: </b>}
          labelClass="w-100"
          name="color"
          required
        />

        <div className="text-center pt-15 d-flex">
          <Button className="me-3 w-50" onClick={handleHide} variant="light">
            Hủy
          </Button>

          <Button className="w-50" type="submit" variant="primary">
            {tag ? 'Cập nhật' : 'Thêm mới'}
          </Button>
        </div>
      </FormField>
    </Modal>
  );
};

export { TagModal };
