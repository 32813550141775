import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';

import { Title } from '@/_metronic/layout/core';
import { useGetCourseDetail } from '@/modules/course/hooks/useGetCourseDetail';

const CourseDetail: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetCourseDetail(id);

  return (
    <>
      <div className="card-body pb-0 card mb-xl-10">
        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <Link
                className={clsx(`nav-link text-active-primary me-6 `, {
                  active: location.pathname === `/course/${id}/overview`,
                })}
                to={`/course/${id}/overview`}
              >
                Tổng quan
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={clsx(`nav-link text-active-primary me-6 `, {
                  active: location.pathname === `/course/${id}/content`,
                })}
                to={`/course/${id}/content`}
              >
                Nội dung
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={clsx(`nav-link text-active-primary me-6 `, {
                  active: location.pathname === `/course/${id}/list-student`,
                })}
                to={`/course/${id}/list-student`}
              >
                Danh sách học viên
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={clsx(`nav-link text-active-primary me-6 `, {
                  active: location.pathname === `/course/${id}/feedback`,
                })}
                to={`/course/${id}/feedback`}
              >
                Ý kiến học viên
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Title>{data?.name}</Title>
    </>
  );
};

export { CourseDetail };
