import { useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { ID, PaginationResponse, QueryOptions } from '@/models';
import { StudentModel } from '@/modules/student/models';

function endpoint(id: ID) {
  return `/course/student/list/${id}`;
}

export function useGetStudentByCourseId(
  id: ID,
  options?: QueryOptions<
    PaginationResponse<StudentModel[]>,
    unknown,
    PaginationResponse<StudentModel[]>,
    ID[]
  >
) {
  const { handleCallApi } = useCallApi();

  function handler() {
    return handleCallApi(() => axiosClient.get<PaginationResponse<StudentModel[]>>(endpoint(id)));
  }

  return useQuery(['course-detail', id], handler, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });
}
