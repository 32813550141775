import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputField } from '@/components/molecules/InputField';
import { Modal } from '@/components/organisms/Modal';
import { ID } from '@/models';
import { useCreateSkill } from '@/modules/course/hooks/skill/useCreateSkill';
import { useGetSkillDetail } from '@/modules/course/hooks/skill/useGetSkillDetail';
import { useUpdateSkill } from '@/modules/course/hooks/skill/useUpdateSkill';
import { FormSkillModal } from '@/modules/course/models';
import { getLabelFormSchema } from '@/modules/course/services/validation';

interface IProps {
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly show?: boolean;
  readonly skillId?: ID;
}

const defaultValues: FormSkillModal = {
  name: '',
};

const SkillModal: FC<IProps> = ({ show, onHide, skillId, onSaved }) => {
  const { mutateAsync: createLabel } = useCreateSkill();

  const { mutateAsync: updateSkillById } = useUpdateSkill();

  const resolver = yupResolver(getLabelFormSchema());
  const formMethods = useForm<FormSkillModal>({ defaultValues, resolver });
  const action = skillId
    ? (formData: FormSkillModal) => updateSkillById({ id: skillId, ...formData })
    : createLabel;
  const onSubmit: SubmitHandler<FormSkillModal> = async (formData) => {
    const res = await action(formData);
    if (!res) return;
    onSaved?.();
    onHide?.();
    formMethods.reset();
  };

  useGetSkillDetail(skillId, {
    enabled: !!skillId,
    onSuccess: (label) => {
      if (!label) return;

      formMethods.reset({
        name: label.name,
      });
    },
  });

  function handleHide() {
    onHide?.();
    formMethods.reset();
  }

  return (
    <Modal
      backdrop={'static'}
      bodyClassName="scroll-y"
      dialogClassName="mw-850px"
      onHide={handleHide}
      show={show}
      title={<h2 className="fw-bolder">{skillId ? 'Chỉnh sửa' : 'Tạo'} kĩ năng</h2>}
      centered
    >
      <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
        <InputField
          autoComplete={false}
          control={formMethods.control}
          groupClass="mb-7 fv-row"
          label={<b>yêu cầu kĩ năng</b>}
          name="name"
          required
        />

        <div className="text-center pt-15 d-flex">
          <Button className="me-3 w-50" onClick={handleHide} variant="light">
            Hủy
          </Button>

          <Button className="w-50" type="submit" variant="primary">
            {skillId ? 'Cập nhật' : 'Thêm mới'}
          </Button>
        </div>
      </FormField>
    </Modal>
  );
};

export { SkillModal };
