import { size } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { KTCard, KTCardBody } from '@/_metronic/helpers';
import { TableGlobalSearch } from '@/components/molecules/TableGlobalSearch';
import { TableListGrouping } from '@/components/molecules/TableListGrouping';
import { Table } from '@/components/organisms/Table';
import { useGetLevelList } from '@/hooks/useGetLevelList';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { useTable } from '@/hooks/useTable';
import { LevelModel } from '@/models/level';
import { CourseListToolbar } from '@/modules/course/components/course-list/CourseListToolbar';
import { getColumnDef } from '@/modules/course/components/level-list/_columns';
import { LevelModal } from '@/modules/course/components/modals/LevelModal';
import { useDeleteLevel } from '@/modules/course/hooks/level/useDeleteLevel';
import { LevelFilterModel } from '@/modules/course/models';

const LevelList = () => {
  const [levelToEdit, setLevelToEdit] = useState<LevelModel>();
  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false);
  const {
    queryState,
    updateQueryState,
    updateSortStateByTable,
    getTableSortState,
    globalSearch,
    handleUpdateGlobalSearch,
  } = useQueryRequest<LevelFilterModel>();

  const { ...tableProps } = useTable<LevelModel>(getTableSortState);
  const columnDef = useMemo(
    () => getColumnDef(tableProps.columnResizeMode, handleClickEdit, handleClickRemove),
    []
  );

  const { data, isLoading, isFetching, refetch } = useGetLevelList();
  const { mutateAsync: deleteLevelById } = useDeleteLevel();
  useEffect(() => {
    updateSortStateByTable(tableProps.sorting);
  }, [tableProps.sorting]);

  function handleClickEdit(level: LevelModel) {
    if (!level) return;
    setLevelToEdit(level);
    setIsVisibleFormModal(true);
  }

  async function handleClickRemove(level: LevelModel) {
    const response = await deleteLevelById(level.id);
    response && refetch();
  }

  function handleSaved() {
    refetch();
    setIsVisibleFormModal(false);
  }

  return (
    <>
      <KTCard>
        <div className="card-header border-0 pt-6">
          <TableGlobalSearch onChange={handleUpdateGlobalSearch} value={globalSearch} />
          <div className="card-toolbar">
            {(() => {
              if (size(tableProps.rowSelection) > 0) {
                return <TableListGrouping keyText={'name'} />;
              }

              return (
                <CourseListToolbar
                  onClickCreateButton={() => {
                    setLevelToEdit(undefined);
                    setIsVisibleFormModal(true);
                  }}
                />
              );
            })()}
          </div>
        </div>

        <KTCardBody className="py-4">
          <Table
            {...tableProps}
            className="cursor-pointer"
            columns={columnDef}
            currentPage={queryState.page}
            data={data}
            isLoading={isFetching || isLoading}
            limit={queryState.limit}
            onLimitChange={(limit) => updateQueryState({ limit })}
            onPageChange={(page) => updateQueryState({ page })}
          />
        </KTCardBody>
      </KTCard>

      {isVisibleFormModal && (
        <LevelModal
          level={levelToEdit}
          onHide={() => {
            setIsVisibleFormModal(false);
          }}
          onSaved={handleSaved}
          show={isVisibleFormModal}
        />
      )}
    </>
  );
};

const LevelListWrapper = () => <LevelList />;

export { LevelListWrapper };
