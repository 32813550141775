import * as Yup from 'yup';

import { AvailableValidationType, vTrans } from '@/utils';

export function urlRules(fieldPrefix: string) {
  function urlRequired(field: string) {
    return Yup.string()
      .url()
      .typeError(vTrans.typeError(`${fieldPrefix}${field}`, AvailableValidationType.url))
      .required(vTrans.required(`${field}`));
  }

  function urlRequiredIf(field: string, dependencyField: string, condition: unknown) {
    const requiredMessage = vTrans.required(`${fieldPrefix}${field}`);
    console.log(requiredMessage);

    return Yup.string()
      .typeError(vTrans.typeError(`${fieldPrefix}${field}`, AvailableValidationType.url))
      .when(dependencyField, { is: condition, then: (schema) => schema.required(requiredMessage) });
  }

  return { urlRequired, urlRequiredIf };
}
