import * as Yup from 'yup';

import { useValidation } from '@/hooks/useValidation';
import { Shape } from '@/models';
import { FormStudentModel } from '@/modules/student/models';

const { stringRequired } = useValidation('');

function getStudentFormSchema() {
  return Yup.object<Shape<FormStudentModel>>({
    email: stringRequired('Tên'),
    full_name: stringRequired('Họ Và Tên'),
    phone: stringRequired('Số Điện thoại'),
    role: stringRequired('Quyền'),
    status: stringRequired('Trạng thái'),
  });
}

export { getStudentFormSchema };
