import * as Yup from 'yup';

import { useValidation } from '@/hooks/useValidation';
import { Shape } from '@/models';
import { FormArticleModel } from '@/modules/article/models/form';
import {
  FormCourseGroupModal,
  FormCourseVideoModal,
  FormLevelModal,
} from '@/modules/course/models/form';

const { stringRequired, fileUploadRequired, arrayRequired } = useValidation('');

function getArticleFormSchema() {
  return Yup.object<Shape<FormArticleModel>>({
    cate_id: stringRequired('Danh mục'),
    content: stringRequired('Nội dung'),
    lead: stringRequired('lead'),
    seo_keywords: stringRequired('từ khóa SEO'),
    seo_title: stringRequired('Tiêu đề SEO'),
    tags: arrayRequired('tags'),
    thumbnail: fileUploadRequired('thumbnail'),
    title: stringRequired('Tiêu đề'),
  });
}

function getGroupVideoFormSchema() {
  return Yup.object<Shape<FormCourseGroupModal>>({
    name: stringRequired('Tên Chương'),
  });
}

function getVideoFormSchema() {
  return Yup.object<Shape<FormCourseVideoModal>>({
    name: stringRequired('Tiêu đề Video'),
    total_time: stringRequired('Độ dài'),
    type: stringRequired('Kiểu'),
    video_url: stringRequired('Link'),
  });
}

function getLevelFormSchema() {
  return Yup.object<Shape<FormLevelModal>>({
    name: stringRequired('Cấp'),
  });
}

function getLabelFormSchema() {
  return Yup.object<Shape<FormLevelModal>>({
    name: stringRequired('Cấp'),
  });
}

export {
  getArticleFormSchema,
  getGroupVideoFormSchema,
  getLabelFormSchema,
  getLevelFormSchema,
  getVideoFormSchema,
};
