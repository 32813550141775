import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { QUERIES } from '@/constants/query';
import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { FormSystemModel } from '@/modules/system/models/form';
import { fakeNetworkResponse } from '@/utils';

function endpoint() {
  return ``;
}

export function useCreateEmployee(options?: MutationOptions<boolean, FormSystemModel>) {
  const { handleCallApi } = useCallApi();
  const queryClient = useQueryClient();
  const createMessage = 'Tạo mới bản ghi thành công';

  function handler(form: FormSystemModel) {
    return handleCallApi(
      async () => {
        await axiosClient.post(endpoint(), { ...form });
        queryClient.invalidateQueries({ queryKey: [QUERIES.EMPLOYEE_LIST] });

        return fakeNetworkResponse(true);
      },
      true,
      createMessage
    );
  }

  return useMutation(handler, options);
}
