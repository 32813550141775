import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputField } from '@/components/molecules/InputField';
import { InputMoneyField } from '@/components/molecules/InputMoneyField';
import { RichTextEditor } from '@/components/molecules/RichTextEditor';
import { SelectField } from '@/components/molecules/SelectField';
import { SelectLabel } from '@/components/molecules/SelectLabel';
import { SelectLevel } from '@/components/molecules/SelectLevel';
import { SelectSkill } from '@/components/molecules/SelectSkill';
import { UploadField } from '@/components/molecules/UploadField';
import { useCreateCourse } from '@/modules/course/hooks/useCreateCourse';
import { useGetCourseDetail } from '@/modules/course/hooks/useGetCourseDetail';
import { useUpdateCourse } from '@/modules/course/hooks/useUpdateCourse';
import { COURSE_STATUS, FormCourseModel } from '@/modules/course/models';
import { getCourseFormSchema } from '@/modules/course/services/validation';
import { convertStringToSlug, history, stringToFileUploadModel } from '@/utils';

const defaultValues: FormCourseModel = {
  description: ' ',
  labels: [],
  levels: [],
  name: ' ',
  price: 0,
  require_skills: [],
  seo_des: '',
  seo_keywords: '',
  seo_title: '',
  status: ' ',
  target_skills: '',
  thumbnail: {},
};

const CourseModal: FC = () => {
  const { id } = useParams();
  const { mutateAsync: createCourse } = useCreateCourse();
  const { mutateAsync: updateCourseById } = useUpdateCourse();
  const { data: courseDetail, isSuccess } = useGetCourseDetail(id);

  const resolver = yupResolver(getCourseFormSchema());
  const formMethods = useForm<FormCourseModel>({ defaultValues, resolver });
  const action = id
    ? (formData: FormCourseModel) =>
        updateCourseById({
          id,
          ...formData,
        })
    : createCourse;
  const onSubmit: SubmitHandler<FormCourseModel> = async (formData) => {
    const slug = convertStringToSlug(id ? courseDetail?.slug || '' : formData.name, !!id);
    const res = await action({
      slug,
      ...formData,
      labels: formData?.labels?.map((label: any) => ({ id: label })),
      levels: formData?.levels?.map((level: any) => ({ id: level })),
      require_skills: formData.require_skills?.map((requireSkill: any) => ({
        id: requireSkill,
      })),
    });
    if (!res) return;
    formMethods.reset();
    id ? history.push(`/course/${id}/overview`) : history.push('/course');
  };

  useEffect(() => {
    formMethods.reset({
      code: courseDetail?.code,
      description: courseDetail?.description,
      labels: courseDetail?.labels?.map((label) => label.id!),
      levels: courseDetail?.levels?.map((level) => level.id!),
      name: courseDetail?.name,
      price: courseDetail?.price,
      require_skills: courseDetail?.require_skills?.map((requireSkill) => requireSkill.id!),
      seo_des: courseDetail?.seo_des,
      seo_keywords: courseDetail?.seo_keywords,
      seo_title: courseDetail?.seo_title,
      status: courseDetail?.status,
      target_skills: courseDetail?.target_skills,
      thumbnail: stringToFileUploadModel(courseDetail?.thumbnail),
      video_intro: courseDetail?.video_intro,
    });
  }, [isSuccess]);

  function handleHide() {
    formMethods.reset();
    history.push(`/course/${id}/overview`);
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="p-4">
        <h2 className="fw-bolder mb-6">{id ? 'Chỉnh sửa' : 'Tạo'} khóa học</h2>
        <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
          <UploadField
            accept={{ 'image/*': [] }}
            control={formMethods.control}
            groupClass="fv-row mb-7"
            label={<b>Thumbnail</b>}
            name="thumbnail"
            type="avatar"
            isOutlineAvatar
            required
          />

          <InputField
            autoComplete={false}
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Tên khóa học</b>}
            name="name"
            required
          />

          <InputField
            autoComplete={false}
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Intro video</b>}
            name="video_intro"
            required
          />

          <RichTextEditor
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Mô tả</b>}
            name="description"
            required
          />

          <SelectField
            control={formMethods.control}
            getOptionValue={(option) => option.value}
            groupClass="mb-7 fv-row"
            label={<b>Trạng thái</b>}
            name="status"
            options={COURSE_STATUS}
            required
          />

          <InputMoneyField
            className="w-100"
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Giá</b>}
            name="price"
            required
          />

          <InputField
            className="w-100"
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>code</b>}
            name="code"
          />

          <InputField
            className="w-100"
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Kỹ năng học được</b>}
            name="target_skills"
            required
          />

          <SelectLevel
            control={formMethods.control}
            getOptionValue={(option) => option.id}
            groupClass="mb-7 fv-row"
            label={<b>Cấp độ</b>}
            name="levels"
            multiple
          />

          <SelectLabel
            control={formMethods.control}
            getOptionValue={(option) => option.id}
            groupClass="mb-7 fv-row w-100"
            label={<b>Nhãn</b>}
            name="labels"
            multiple
          />

          <SelectSkill
            control={formMethods.control}
            getOptionValue={(option) => option.id}
            groupClass="mb-7 fv-row"
            label={<b>kĩ năng yêu cầu khi tham gia</b>}
            name="require_skills"
            multiple
          />
          <InputField
            className="w-100"
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>SEO tiều đề</b>}
            name="seo_title"
          />

          <InputField
            className="w-100"
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>SEO từ khoá</b>}
            name="seo_keywords"
          />

          <InputField
            className="w-100 rou"
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>SEO mô tả</b>}
            name="seo_des"
          />

          <div className="text-center pt-15 d-flex">
            <Button className="me-3 w-50" onClick={handleHide} variant="light">
              Hủy
            </Button>

            <Button className="w-50" type="submit" variant="primary">
              {id ? 'Cập nhật khóa học' : 'Tạo mới khóa học'}
            </Button>
          </div>
        </FormField>
      </div>
    </div>
  );
};

export { CourseModal };
