import { ColumnDef, ColumnResizeMode, createColumnHelper } from '@tanstack/react-table';
import { Image } from 'antd';

import { TableActionCellFlatStyle } from '@/components/molecules/TableActionCellFlatStyle';
import { TableHeaderCell } from '@/components/molecules/TableHeaderCell';
import { TableTagCell } from '@/components/molecules/TableTagCell';
import { TableVisibilityColumnCell } from '@/components/molecules/TableVisibilityColumnCell';
import { ArticleStatus } from '@/models';
import { ArticleModel } from '@/modules/article/models/response';
import { getTimeFormat, history } from '@/utils';

function getColumnDef(
  columnResizeMode: ColumnResizeMode = 'onChange',
  onClickEdit?: (data: ArticleModel) => void,
  onClickRemove?: (data: ArticleModel) => void,
  onClickClear?: (data: ArticleModel) => void
): ColumnDef<ArticleModel, any>[] {
  const columnHelper = createColumnHelper<ArticleModel>();

  return [
    columnHelper.accessor('thumbnail', {
      cell: ({ row }) => (
        <Image className="rounded-1" height={50} preview={false} src={row.original.thumbnail} />
      ),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-40px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="thumbnail"
        />
      ),
    }),

    columnHelper.accessor('title', {
      cell: ({ row }) => {
        return (
          <span
            className="text-underline-hover cursor-pointer"
            onClick={() => {
              history.push(`article/${row.original.id}/overview`);
            }}
            style={{ whiteSpace: 'break-spaces' }}
          >
            {row.original.title}
          </span>
        );
      },
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-120px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Tiêu đề"
        />
      ),
    }),

    columnHelper.accessor('status', {
      cell: ({ row }) => {
        const isActive = row.original.status;

        return (
          <TableTagCell color={isActive === ArticleStatus.publish ? 'success' : 'warning'} isLight>
            {isActive === ArticleStatus.publish ? 'Công khai' : 'Bản nháp'}
          </TableTagCell>
        );
      },
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-40px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="trạng thái"
        />
      ),
    }),

    columnHelper.accessor('category', {
      cell: ({ row }) => (
        <TableTagCell color={'info'} isLight>
          {row.original?.category?.name}
        </TableTagCell>
      ),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-40px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Danh mục"
        />
      ),
    }),

    columnHelper.accessor('created_at', {
      cell: ({ getValue }) => getTimeFormat(getValue()),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Ngày tạo"
        />
      ),
    }),

    columnHelper.accessor('updated_at', {
      cell: ({ getValue }) => getTimeFormat(getValue()),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Ngày cập nhật"
        />
      ),
    }),

    columnHelper.accessor((row) => row.id, {
      cell: ({ row }) => (
        <TableActionCellFlatStyle
          data={row.original}
          isShowViewButton={false}
          onClickClear={onClickClear}
          onClickEdit={onClickEdit}
          onClickRemove={onClickRemove}
          isShowClearButton
        />
      ),
      enableHiding: false,
      enableResizing: false,
      enableSorting: false,
      header: ({ header, table }) => (
        <TableHeaderCell className="w-60px" columnResizeMode={columnResizeMode} header={header}>
          <TableVisibilityColumnCell
            className="justify-content-end"
            menuClassName="text-primary"
            table={table}
            title="tác vụ"
          />
        </TableHeaderCell>
      ),
      id: 'actions',
    }),
  ];
}

export { getColumnDef };
