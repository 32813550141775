import { isString, size } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { KTCard, KTCardBody } from '@/_metronic/helpers';
import { TableGlobalSearch } from '@/components/molecules/TableGlobalSearch';
import { TableListGrouping } from '@/components/molecules/TableListGrouping';
import { Table } from '@/components/organisms/Table';
import { useGetLabelList } from '@/hooks/useGetLabelList';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { useTable } from '@/hooks/useTable';
import { LabelModel } from '@/models/label';
import { CourseListToolbar } from '@/modules/course/components/course-list/CourseListToolbar';
import { getColumnDef } from '@/modules/course/components/label-list/_columns';
import { LabelModal } from '@/modules/course/components/modals/LabelModal';
import { useDeleteLabel } from '@/modules/course/hooks/label/useDeleteLabel';
import { LabelFilterModel } from '@/modules/course/models';

const LabelList = () => {
  const [labelIdToEdit, setLabelIdToEdit] = useState('');
  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false);
  const {
    queryState,
    updateQueryState,
    updateSortStateByTable,
    getTableSortState,
    globalSearch,
    handleUpdateGlobalSearch,
  } = useQueryRequest<LabelFilterModel>();

  const { ...tableProps } = useTable<LabelModel>(getTableSortState);
  const columnDef = useMemo(
    () => getColumnDef(tableProps.columnResizeMode, handleClickEdit, handleClickRemove),
    []
  );

  const { isLoading, isFetching, data, refetch } = useGetLabelList();
  const { mutateAsync: deleteLabelById } = useDeleteLabel();
  useEffect(() => {
    updateSortStateByTable(tableProps.sorting);
  }, [tableProps.sorting]);

  function handleClickEdit(label: LabelModel) {
    if (!isString(label.id)) return;
    setLabelIdToEdit(label.id);
    setIsVisibleFormModal(true);
  }

  async function handleClickRemove(level: LabelModel) {
    const response = await deleteLabelById(level.id);
    response && refetch();
  }

  function handleSaved() {
    refetch();
    setIsVisibleFormModal(false);
  }

  return (
    <>
      <KTCard>
        <div className="card-header border-0 pt-6">
          <TableGlobalSearch onChange={handleUpdateGlobalSearch} value={globalSearch} />
          <div className="card-toolbar">
            {(() => {
              if (size(tableProps.rowSelection) > 0) {
                return <TableListGrouping keyText={'name'} />;
              }

              return <CourseListToolbar onClickCreateButton={() => setIsVisibleFormModal(true)} />;
            })()}
          </div>
        </div>

        <KTCardBody className="py-4">
          <Table
            {...tableProps}
            className="cursor-pointer"
            columns={columnDef}
            currentPage={queryState.page}
            data={data}
            isLoading={isFetching || isLoading}
            limit={queryState.limit}
            onLimitChange={(limit) => updateQueryState({ limit })}
            onPageChange={(page) => updateQueryState({ page })}
          />
        </KTCardBody>
      </KTCard>

      {isVisibleFormModal && (
        <LabelModal
          labelId={labelIdToEdit}
          onHide={() => {
            setIsVisibleFormModal(false);
            setLabelIdToEdit('');
          }}
          onSaved={handleSaved}
          show={isVisibleFormModal}
        />
      )}
    </>
  );
};

const LabelListWrapper = () => <LabelList />;

export { LabelListWrapper };
