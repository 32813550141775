import { Button, Collapse, Image } from 'antd';
import { FC, useState } from 'react';

import { KTSVG } from '@/_metronic/helpers';
import { TableTagCell } from '@/components/molecules/TableTagCell';
import { ID } from '@/models';
import { CreateGroupModal } from '@/modules/course/components/course-detail/content/components/course-group/CreateGroupModal';
import { UpdateGroupModal } from '@/modules/course/components/course-detail/content/components/course-group/UpdateGroupModal';
import { CourseVideoModal } from '@/modules/course/components/course-detail/content/components/course-video/CourseVideoModal';
import { CourseModel } from '@/modules/course/models';

interface IProps {
  readonly refetch: () => void;
  readonly courseDetail?: CourseModel;
}

export const CourseMenu: FC<IProps> = ({ courseDetail, refetch }) => {
  const [openPanels, setOpenPanels] = useState<number[]>([]);
  const [openCollapses, setOpenCollapses] = useState<number[]>([]);
  const [isEditChap, setIsEditChap] = useState<ID>();
  const groupVideos = courseDetail?.group_videos;

  return (
    <Collapse
      activeKey={openCollapses}
      bordered={false}
      expandIconPosition="start"
      onChange={(e) => setOpenCollapses([Number(e)])}
      style={{ background: '#fff' }}
      accordion
    >
      {groupVideos?.map((groupVideo, indexGroupVideo) => (
        <Collapse.Panel
          key={indexGroupVideo}
          className="mb-4 fs-2 rounded"
          extra={
            isEditChap === groupVideo.id || (
              <div
                onClick={(event) => {
                  setIsEditChap(groupVideo.id);
                  event.stopPropagation();
                }}
              >
                <Button size="large" type="dashed">
                  <KTSVG path="/media/icons/duotune/general/edit.svg" />
                </Button>
              </div>
            )
          }
          header={
            isEditChap === groupVideo.id ? (
              <>
                <UpdateGroupModal
                  groupName={groupVideo.name}
                  groupVideoID={groupVideo.id}
                  onHide={() => setIsEditChap('')}
                  onSaved={refetch}
                  order={indexGroupVideo + 1}
                />
              </>
            ) : (
              <div className="w-100 d-flex align-items-center pt-2" style={{ color: '#666' }}>
                <Image
                  preview={false}
                  src="/media/icons/duotune/general/layer_group.svg"
                  width={20}
                />
                <span className="ms-3">
                  {' '}
                  {groupVideo.name}
                  <TableTagCell backgroundColor={'#48b8f6'} className="ms-3 fs-5">
                    {groupVideo.videos.length} bài
                  </TableTagCell>
                </span>
              </div>
            )
          }
          showArrow={false}
          style={{ backgroundColor: 'rgb(239, 242, 251)' }}
        >
          <Collapse
            activeKey={openPanels}
            expandIcon={() => (
              <Button size="middle" type="dashed">
                <KTSVG path="/media/icons/duotune/general/edit.svg" />
              </Button>
            )}
            expandIconPosition="end"
            onChange={(e) => {
              setOpenPanels([Number(e)]);
            }}
            accordion
          >
            {groupVideo.videos?.map(
              (video, indexVideo) =>
                video.group_id === groupVideo.id &&
                video.course_id === courseDetail?.id && (
                  <Collapse.Panel
                    key={indexVideo}
                    collapsible="icon"
                    header={
                      <div className="d-flex">
                        <div className="pe-2">
                          <KTSVG path="/media/icons/duotune/general/play_video.svg" />
                        </div>
                        <strong>{video.name}</strong>
                        <span className="px-3">•</span>
                        <span className="px-1">Độ dài:</span>
                        <strong>{video.total_time}</strong>
                        <span className="px-3">•</span>
                        <TableTagCell
                          backgroundColor={video.type === 'premium' ? '#0095e8' : undefined}
                          color={video.type === 'free' ? 'success' : undefined}
                        >
                          {video.type}
                        </TableTagCell>
                        <span className="px-3">•</span>
                        <span>Link video: </span>
                        <a className="px-3" href={video.video_url} rel="noreferrer" target="_blank">
                          {video.video_url}
                        </a>
                      </div>
                    }
                  >
                    <CourseVideoModal
                      courseID={courseDetail?.id}
                      groupVideo={groupVideo}
                      onHide={() => setOpenPanels([])}
                      onSaved={refetch}
                      videoDetail={video}
                    />
                  </Collapse.Panel>
                )
            )}
            <Collapse.Panel
              key={-2}
              className="bg-muted"
              header={
                <div className="d-flex justify-content-center">
                  <div className="pe-2">
                    <KTSVG path="/media/icons/duotune/general/add.svg" />
                  </div>
                  <div className="text-dark fw-bolder">Thêm bài học</div>
                </div>
              }
              showArrow={false}
            >
              <CourseVideoModal
                courseID={courseDetail?.id}
                groupVideo={groupVideo}
                onHide={() => setOpenPanels([])}
                onSaved={refetch}
              />
            </Collapse.Panel>
          </Collapse>
        </Collapse.Panel>
      ))}
      <Collapse.Panel
        key={-1}
        className="mb-2 fs-2 rounded"
        header={
          <div className="d-flex justify-content-center">
            <div className="pe-2">
              <KTSVG path="/media/icons/duotune/general/add-light.svg" />
            </div>
            <div className="text-primary" style={{ color: '#666' }}>
              Thêm chương
            </div>
          </div>
        }
        showArrow={false}
        style={{ backgroundColor: 'rgb(239, 242, 251)' }}
      >
        <CreateGroupModal
          groupVideos={groupVideos}
          onHide={() => setOpenCollapses([])}
          onSaved={refetch}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
