import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputField } from '@/components/molecules/InputField';
import { SelectField } from '@/components/molecules/SelectField';
import { Modal } from '@/components/organisms/Modal';
import { useUpdateStudent } from '@/modules/student/hooks/useUpdateStudent';
import {
  FormStudentModel,
  STUDENT_ROLE,
  STUDENT_STATUS,
  StudentModel,
} from '@/modules/student/models';
import { getStudentFormSchema } from '@/modules/student/services/validation';

interface IProps {
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly show?: boolean;
  readonly student?: StudentModel;
}

const StudentModal: FC<IProps> = ({ show, onHide, onSaved, student }) => {
  const { mutateAsync: updateStudent } = useUpdateStudent();
  const resolver = yupResolver(getStudentFormSchema());
  const formMethods = useForm<FormStudentModel>({ resolver });

  const onSubmit: SubmitHandler<FormStudentModel> = (formData) => {
    updateStudent(formData);
    onSaved?.();
    formMethods.reset();
  };

  useEffect(() => {
    formMethods.reset({
      email: student?.email,
      full_name: student?.full_name,
      phone: student?.phone,
      role: student?.role,
      status: student?.status,
    });
  }, [student]);

  function handleHide() {
    onHide?.();
    formMethods.reset();
  }

  return (
    <Modal
      backdrop={'static'}
      bodyClassName="scroll-y"
      dialogClassName="mw-850px"
      onHide={handleHide}
      show={show}
      title={<h2 className="fw-bolder">Cập nhật học viên</h2>}
      centered
    >
      <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
        <InputField
          autoComplete={false}
          control={formMethods.control}
          groupClass="mb-7 fv-row"
          label={<b>Email</b>}
          name="email"
          required
        />
        <InputField
          autoComplete={false}
          control={formMethods.control}
          groupClass="mb-7 fv-row"
          label={<b>Tên</b>}
          name="full_name"
          required
        />
        <InputField
          autoComplete={false}
          control={formMethods.control}
          groupClass="mb-7 fv-row"
          label={<b>Số điện thoại</b>}
          name="phone"
          required
        />
        <SelectField
          control={formMethods.control}
          getOptionValue={(option) => option.value}
          groupClass="mb-7 fv-row"
          label={<b>Trạng thái</b>}
          name="status"
          options={STUDENT_STATUS}
          required
        />
        <SelectField
          control={formMethods.control}
          getOptionValue={(option) => option.value}
          groupClass="mb-7 fv-row"
          label={<b>Quyền</b>}
          name="role"
          options={STUDENT_ROLE}
          required
        />
        <div className="text-center pt-15 d-flex">
          <Button className="me-3 w-50" onClick={handleHide} variant="light">
            Hủy
          </Button>

          <Button className="w-50" type="submit" variant="primary">
            Cập nhật
          </Button>
        </div>
      </FormField>
    </Modal>
  );
};

export { StudentModal };
