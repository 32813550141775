import { getClientDateTime } from '@/utils';

export enum AvailableValidationType {
  array = 'array',
  boolean = 'boolean',
  number = 'number',
  object = 'object',
  string = 'string',
  url = 'url',
}

interface IValidationTranslator {
  confirm: (field: string, isTranslateField: boolean) => string;
  matches: (field: string, isTranslateField: boolean) => string;
  max: (field: string, max: number, isTranslateField: boolean) => string;
  min: (field: string, min: number, isTranslateField: boolean) => string;
  required: (field: string, isTranslateField: boolean) => string;
  typeError: (
    field: string,
    type: AvailableValidationType,
    isTranslateField: boolean,
    isTranslateType: boolean
  ) => string;
}

class ValidationTranslator implements IValidationTranslator {
  public confirm(field: string): string {
    return 'vui lòng chọn xác nhận ' + field;
  }

  public matches(field: string): string {
    return field + ' chưa đúng định dạng';
  }

  public email(field: string): string {
    return field + ' chưa đúng định dạng';
  }

  public max(field: string, max: number | string): string {
    return field + ' phải bé hơn ' + max;
  }

  public min(field: string, min: number | string): string {
    return field + ' phải lớn hơn ' + min;
  }

  public minDate(field: string, min: string): string {
    return field + ' phải lớn hơn ' + getClientDateTime(min);
  }

  public maxDate(field: string, max: string): string {
    return field + ' phải bé hơn ' + getClientDateTime(max);
  }

  public required(field: string): string {
    return field + ' là bắt buộc';
  }

  public typeError(field: string, type: AvailableValidationType): string {
    return field + ' bắt buộc là ' + type;
  }
}

export const vTrans = new ValidationTranslator();
