import { useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { FormOrderModel } from '@/modules/order/models/form';
import { fakeNetworkResponse } from '@/utils';

function endpoint() {
  return '/order/status/update';
}

export function useUpdateOrder(options?: MutationOptions<boolean, FormOrderModel>) {
  const { handleCallApi } = useCallApi();
  const updateMessage = 'Cập nhật bản ghi thành công';

  function handler(form: FormOrderModel) {
    return handleCallApi(
      async () => {
        await axiosClient.patch(endpoint(), form);

        return fakeNetworkResponse(true);
      },
      true,
      updateMessage
    );
  }

  return useMutation(handler, options);
}
