import { useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { QueryOptions } from '@/models';
import { SkillModel } from '@/models/skill';

function endpoint() {
  return `/course/skill/list`;
}

export function useGetSkillList(
  options?: QueryOptions<SkillModel[], unknown, SkillModel[], string[]>
) {
  const { handleCallApi } = useCallApi();

  function handler() {
    return handleCallApi(() => axiosClient.get<SkillModel[]>(endpoint()));
  }

  return useQuery(['course-skill-list'], handler, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });
}
