import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { TypeClear } from '@/models/clearCache';
import { fakeNetworkResponse } from '@/utils';

export function useGetCache(options?: MutationOptions<boolean, { slug: string; type: TypeClear }>) {
  const { handleCallApi } = useCallApi();
  const createMessage = 'Xóa cache thành công';

  function handler({ type, slug }: { slug: string; type: TypeClear }) {
    return handleCallApi(
      async () => {
        await axios.get(`https://microlap.vn/cache/clean?type=${type}&key=${slug}`);

        return fakeNetworkResponse(true);
      },
      true,
      createMessage
    );
  }

  return useMutation(handler, options);
}
