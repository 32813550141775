import { ColumnDef, ColumnResizeMode, createColumnHelper } from '@tanstack/react-table';

import { TableActionCellFlatStyle } from '@/components/molecules/TableActionCellFlatStyle';
import { TableHeaderCell } from '@/components/molecules/TableHeaderCell';
import { TableTagCell } from '@/components/molecules/TableTagCell';
import { TableVisibilityColumnCell } from '@/components/molecules/TableVisibilityColumnCell';
import { TagModel } from '@/modules/article/models/response';

function getColumnDef(
  columnResizeMode: ColumnResizeMode = 'onChange',
  onClickEdit?: (data: TagModel) => void,
  onClickRemove?: (data: TagModel) => void
): ColumnDef<TagModel, any>[] {
  const columnHelper = createColumnHelper<TagModel>();

  return [
    columnHelper.accessor('name', {
      cell: ({ row }) => (
        <TableTagCell backgroundColor={row.original.color || '#999'} className="me-1 fs-3" isLight>
          {row.original.name}
        </TableTagCell>
      ),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="tags"
        />
      ),
    }),

    columnHelper.accessor((row) => row.id, {
      cell: ({ row }) => (
        <TableActionCellFlatStyle
          data={row.original}
          isShowViewButton={false}
          onClickEdit={onClickEdit}
          onClickRemove={onClickRemove}
        />
      ),
      enableHiding: false,
      enableResizing: false,
      enableSorting: false,
      header: ({ header, table }) => (
        <TableHeaderCell className="w-35px" columnResizeMode={columnResizeMode} header={header}>
          <TableVisibilityColumnCell
            className="justify-content-end"
            menuClassName="text-primary"
            table={table}
            title="tác vụ"
          />
        </TableHeaderCell>
      ),
      id: 'actions',
    }),
  ];
}

export { getColumnDef };
