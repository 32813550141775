import { useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { ID, QueryOptions } from '@/models';
import { ArticleModel } from '@/modules/article/models/response';

function endpoint(id: ID) {
  return `/article/detail/${id}`;
}

export function useGetArticleDetail(
  id: ID,
  options?: QueryOptions<ArticleModel, unknown, ArticleModel, ID[]>
) {
  const { handleCallApi } = useCallApi();

  function handler() {
    return handleCallApi(() => axiosClient.get<ArticleModel>(endpoint(id)));
  }

  return useQuery(['post-detail', id], handler, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });
}
