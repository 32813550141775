import { useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { FormLevelModal } from '@/modules/course/models';
import { fakeNetworkResponse, prepareDataToRequest } from '@/utils';

function endpoint() {
  return `/course/level/add`;
}

export function useCreateLevel(options?: MutationOptions<boolean, FormLevelModal>) {
  const { handleCallApi } = useCallApi();
  const createMessage = 'Tạo mới bản ghi thành công';

  function handler(form: FormLevelModal) {
    return handleCallApi(
      async () => {
        await axiosClient.post(endpoint(), prepareDataToRequest(form));

        return fakeNetworkResponse(true);
      },
      true,
      createMessage
    );
  }

  return useMutation(handler, options);
}
