import * as Yup from 'yup';

import { useValidation } from '@/hooks/useValidation';
import { FormLoginModel, Shape } from '@/models';

const { stringRequired } = useValidation('');

function getLoginFormSchema() {
  return Yup.object<Shape<FormLoginModel>>({
    email: stringRequired('Email'),
    password: stringRequired('Mật khẩu'),
  }).required();
}

export { getLoginFormSchema };
