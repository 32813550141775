import React, { FC } from 'react';

import { MenuInner } from '@/_metronic/layout/components/header/MenuInner';
import { useAuth } from '@/hooks/useAuth';

const Header: FC = () => {
  const { currentUser } = useAuth();

  return (
    <div
      className="header-menu align-items-stretch"
      data-kt-drawer="true"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-direction="end"
      data-kt-drawer-name="header-menu"
      data-kt-drawer-overlay="true"
      data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-swapper="true"
      data-kt-swapper-mode="prepend"
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <div
        className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-center"
        data-kt-menu="true"
        id="#kt_header_menu"
      >
        <MenuInner />
        <h1 className="d-flex flex-column text-dark fw-bold my-0 fs-1 align-items-center">
          <div className="text-primary d-flex flex-column">
            {'Xin chào'} {currentUser?.full_name}
            <small className="text-muted fs-6 fw-semibold ms-1 pt-1">Admin</small>
          </div>
        </h1>
      </div>
    </div>
  );
};

export { Header };
