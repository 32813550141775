import { ConfigProvider } from 'antd';
import { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';

import { LayoutProvider, LayoutSplashScreen } from '@/_metronic/layout/core';
import { MasterInit } from '@/_metronic/layout/MasterInit';
import { Toast } from '@/components/molecules/Toast';
import { AuthInit } from '@/modules/auth';
import { MenuInit } from '@/modules/menu/core/Menu';

const App = () => {
  const locale = 'en';

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <IntlProvider locale={locale}>
        <ConfigProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MenuInit />
              <MasterInit />
              <Toast />
            </AuthInit>
          </LayoutProvider>
        </ConfigProvider>
      </IntlProvider>
    </Suspense>
  );
};

export { App };
