import { ColumnDef, ColumnResizeMode, createColumnHelper } from '@tanstack/react-table';

import { TableHeaderCell } from '@/components/molecules/TableHeaderCell';
import { StudentModel } from '@/modules/student/models';

function getColumnDef(
  columnResizeMode: ColumnResizeMode = 'onChange'
): ColumnDef<StudentModel, any>[] {
  const columnHelper = createColumnHelper<StudentModel>();

  return [
    columnHelper.accessor('full_name', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Tên"
        />
      ),
    }),

    columnHelper.accessor('email', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="email"
        />
      ),
    }),

    columnHelper.accessor('phone', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="số điện thoại"
        />
      ),
    }),

    columnHelper.accessor('status', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="trạng thái"
        />
      ),
    }),

    columnHelper.accessor('score', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="điểm"
        />
      ),
    }),

    columnHelper.accessor('amount_received', {
      cell: ({ getValue }) => getValue() || 0,
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Tiền thu"
        />
      ),
    }),

    columnHelper.accessor('count_courses', {
      cell: ({ getValue }) => getValue() || 0,
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="số khóa học đã mua"
        />
      ),
    }),
  ];
}

export { getColumnDef };
