import { isString, size } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { KTCard, KTCardBody } from '@/_metronic/helpers';
import { TableGlobalSearch } from '@/components/molecules/TableGlobalSearch';
import { TableListGrouping } from '@/components/molecules/TableListGrouping';
import { Table } from '@/components/organisms/Table';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { useTable } from '@/hooks/useTable';
import { getColumnDef } from '@/modules/student/components/student-list/_columns';
import { StudentFilter } from '@/modules/student/components/student-list/StudentListFilter';
import { StudentModal } from '@/modules/student/components/StudentModal';
import { useGetStudentList } from '@/modules/student/hooks/useGetStudentList';
import { StudentFilterModel, StudentModel } from '@/modules/student/models';

const StudentList = () => {
  const [customerToEdit, setCustomerToEdit] = useState<StudentModel>();
  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false);
  const {
    queryState,
    updateQueryState,
    updateSortStateByTable,
    getTableSortState,
    queryString,
    updateFiltersState,
    globalSearch,
    handleUpdateGlobalSearch,
  } = useQueryRequest<StudentFilterModel>();

  const { table, ...tableProps } = useTable<StudentModel>(getTableSortState);
  const columnDef = useMemo(() => getColumnDef(tableProps.columnResizeMode, handleClickEdit), []);
  const customerToDelete = useMemo(
    () => table?.getSelectedRowModel().flatRows.map(({ original }) => original),
    [table]
  );
  const { data, isLoading, isFetching, refetch } = useGetStudentList(queryString);

  useEffect(() => {
    updateSortStateByTable(tableProps.sorting);
  }, [tableProps.sorting]);

  function handleClickEdit(user: StudentModel) {
    if (!isString(user.id)) return;
    setCustomerToEdit(user);
    setIsVisibleFormModal(true);
  }

  function handleSaved() {
    refetch();
    setIsVisibleFormModal(false);
  }

  function handleOnFiltersConfirm(formData: Partial<StudentFilterModel>) {
    const updates: StudentFilterModel = {
      ...formData,
      name: formData.name || '',
      phone_number: formData.phone_number || '',
    };
    updateFiltersState(updates);
  }

  return (
    <>
      <KTCard>
        <div className="card-header border-0 pt-6">
          <TableGlobalSearch onChange={handleUpdateGlobalSearch} value={globalSearch} />
          <div className="card-toolbar">
            {(() => {
              if (size(tableProps.rowSelection) > 0) {
                return <TableListGrouping keyText={'user_name'} selected={customerToDelete} />;
              }
            })()}
            <StudentFilter
              initFiltersValues={queryState.filters}
              onFiltersConfirm={handleOnFiltersConfirm}
            />
          </div>
        </div>

        <KTCardBody className="py-4">
          <Table
            {...tableProps}
            columns={columnDef}
            currentPage={queryState.page}
            data={data?.rows}
            isLoading={isFetching || isLoading}
            limit={queryState.limit}
            onLimitChange={(limit) => updateQueryState({ limit })}
            onPageChange={(page) => updateQueryState({ page })}
            total={data?.total_rows}
          />
        </KTCardBody>
      </KTCard>

      {isVisibleFormModal && (
        <StudentModal
          onHide={() => {
            setIsVisibleFormModal(false);
          }}
          onSaved={handleSaved}
          show={isVisibleFormModal}
          student={customerToEdit}
        />
      )}
    </>
  );
};

const StudentListWrapper = () => <StudentList />;

export { StudentListWrapper };
