import { useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { ID, QueryOptions } from '@/models';
import { SkillModel } from '@/modules/course/models';

function endpoint(id: ID) {
  return `/course/skill/detail/${id}`;
}

export function useGetSkillDetail(
  id: ID,
  options?: QueryOptions<SkillModel, unknown, SkillModel, ID[]>
) {
  const { handleCallApi } = useCallApi();

  function handler() {
    return handleCallApi(() => axiosClient.get<SkillModel>(endpoint(id)));
  }

  return useQuery(['label-detail', id], handler, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });
}
