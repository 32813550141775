import { isString, size } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { KTCard, KTCardBody } from '@/_metronic/helpers';
import { TableGlobalSearch } from '@/components/molecules/TableGlobalSearch';
import { TableListGrouping } from '@/components/molecules/TableListGrouping';
import { Table } from '@/components/organisms/Table';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { useTable } from '@/hooks/useTable';
import { getColumnDef } from '@/modules/system/components/system-list/_columns';
import { SystemListToolbar } from '@/modules/system/components/system-list/SystemListToolbar';
import { EmployeeModal } from '@/modules/system/components/SystemModal';
import { useDeleteEmployee } from '@/modules/system/hooks/useDeleteEmployee';
import { useGetEmployeeList } from '@/modules/system/hooks/useGetEmployeeList';
import { SystemFilterModel } from '@/modules/system/models/filters';
import { SystemModel } from '@/modules/system/models/response';

//fake data
export const dataSystem: SystemModel[] = [
  {
    category: 'khóa học',
    created_at: '11/11/2022',
    id: '123123j123j123j1231',
    level: 5,
    name_course: 'react js',
    number_of_lessons: 4,
    number_of_student: 2,
    price: 1700000,
    status: 'mở khóa',
  },
  {
    category: 'hướng dẫn',
    created_at: '9/12/2022',
    id: '123233j123j123j1231',
    level: 1,
    name_course: 'vue js',
    number_of_lessons: 7,
    number_of_student: 6,
    price: 1200000,
    status: 'mở khóa',
  },
  {
    category: 'khóa học',
    created_at: '3/2/2022',
    id: '113123j123j123j1231',
    level: 3,
    name_course: 'javascript',
    number_of_lessons: 9,
    number_of_student: 13,
    price: 2000000,
    status: 'mở khóa',
  },
];

const SystemList = () => {
  const [employeeIdToEdit, setEmployeeIdToEdit] = useState('');
  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false);
  const {
    queryState,
    updateQueryState,
    updateSortStateByTable,
    getTableSortState,
    queryString,
    globalSearch,
    handleUpdateGlobalSearch,
  } = useQueryRequest<SystemFilterModel>();

  const { ...tableProps } = useTable<SystemModel>(getTableSortState);
  const columnDef = useMemo(
    () => getColumnDef(tableProps.columnResizeMode, handleClickEdit, handleClickRemove),
    []
  );

  const { isFetching, isLoading, refetch } = useGetEmployeeList(queryString);
  const { mutateAsync: deleteEmployeeByIds } = useDeleteEmployee();
  useEffect(() => {
    updateSortStateByTable(tableProps.sorting);
  }, [tableProps.sorting]);

  function handleClickEdit(user: SystemModel) {
    if (!isString(user.id)) return;
    setEmployeeIdToEdit(user.id);
    setIsVisibleFormModal(true);
  }

  async function handleClickRemove(user: SystemModel) {
    const response = await deleteEmployeeByIds([user.id]);
    response && refetch();
  }
  function handleRowClick(user: SystemModel) {
    console.log(user);
  }
  function handleSaved() {
    refetch();
    setIsVisibleFormModal(false);
  }
  async function handleConfirmDelete(users: SystemModel[]) {
    const response = await deleteEmployeeByIds(users.map((user) => user.id));
    response && refetch();
  }

  return (
    <div data-testid="employee-list-element">
      <KTCard>
        <div className="card-header border-0 pt-6">
          <TableGlobalSearch onChange={handleUpdateGlobalSearch} value={globalSearch} />
          <div className="card-toolbar">
            {(() => {
              if (size(tableProps.rowSelection) > 0) {
                return (
                  <TableListGrouping keyText={'user_name'} onConfirmDelete={handleConfirmDelete} />
                );
              }

              return <SystemListToolbar onClickCreateButton={() => setIsVisibleFormModal(true)} />;
            })()}
          </div>
        </div>

        <KTCardBody className="py-4">
          <Table
            {...tableProps}
            columns={columnDef}
            currentPage={queryState.page}
            data={dataSystem}
            isLoading={isFetching || isLoading}
            limit={queryState.limit}
            onLimitChange={(limit) => updateQueryState({ limit })}
            onPageChange={(page) => updateQueryState({ page })}
            onRowClick={handleRowClick}
          />
        </KTCardBody>
      </KTCard>

      {isVisibleFormModal && (
        <EmployeeModal
          employeeId={employeeIdToEdit}
          onHide={() => {
            setIsVisibleFormModal(false);
            setEmployeeIdToEdit('');
          }}
          onSaved={handleSaved}
          show={isVisibleFormModal}
        />
      )}
    </div>
  );
};

const SystemListWrapper = () => <SystemList />;

export { SystemListWrapper };
