import { FC, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';

import { getCSSVariableValue } from '@/_metronic/assets/ts/_utils';
import { WithChildren } from '@/_metronic/helpers';
import { MasterLayout } from '@/_metronic/layout/MasterLayout';
import ArticlePage from '@/modules/article/ArticlePage';
import CoursePage from '@/modules/course/CoursePage';
import OrderPage from '@/modules/order/OrderPage';
import ReportPage from '@/modules/report/ReportPage';
import StudentPage from '@/modules/student/StudentPage';
import SystemPage from '@/modules/system/SystemPage';

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route element={<Navigate to="/dashboard" />} path="auth/*" />
        {/* Pages */}
        <Route
          element={
            <SuspensedView>
              <ReportPage />
            </SuspensedView>
          }
          path="dashboard/*"
        />
        <Route
          element={
            <SuspensedView>
              <ArticlePage />
            </SuspensedView>
          }
          path="article/*"
        />
        <Route
          element={
            <SuspensedView>
              <StudentPage />
            </SuspensedView>
          }
          path="student/*"
        />
        <Route
          element={
            <SuspensedView>
              <CoursePage />
            </SuspensedView>
          }
          path="course/*"
        />
        <Route
          element={
            <SuspensedView>
              <SystemPage />
            </SuspensedView>
          }
          path="system/*"
        />
        <Route
          element={
            <SuspensedView>
              <OrderPage />
            </SuspensedView>
          }
          path="order/*"
        />
        {/* Page Not Found */}
        <Route element={<Navigate to="/error/404" />} path="*" />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
