import { FileUploadModel, ID, LabelModel, LevelModel, SkillModel, VideoType } from '@/models';

export interface FormCourseModel {
  description: string;
  name: string;
  price: number;
  status: string;
  target_skills: string;
  thumbnail: FileUploadModel;
  code?: string;
  id?: ID;
  labels?: LabelModel[] | string[];
  levels?: LevelModel[] | string[];
  require_skills?: SkillModel[] | string[];
  seo_des?: string;
  seo_keywords?: string;
  seo_title?: string;
  slug?: string;
  video_intro?: string;
}

export interface FormCourseGroupModal {
  name: string;
  course_id?: ID;
  id?: ID;
  order?: number;
}

export interface FormCourseVideoModal {
  name: string;
  total_time: string;
  type: VideoType;
  video_url: string;
  course_id?: ID;
  group_id?: ID;
  id?: ID;
  order?: number;
}

export interface FormSwapGroupModal {
  order: number;
  id?: ID;
}

export interface FormLevelModal {
  name: string;
  id?: ID;
}

export interface FormLabelModal {
  color: string;
  name: string;
  id?: ID;
}

export interface FormSkillModal {
  name: string;
  id?: ID;
}

const courseStatusValue = {
  active: 'ACTIVE',
  unactive: 'PENDING',
};

export const COURSE_STATUS = [
  { label: 'Kích hoạt', value: courseStatusValue.active },
  { label: 'Chưa kích hoạt', value: courseStatusValue.unactive },
];

export const VIDEO_TYPE_OPTION = [
  { label: 'Free', value: VideoType.free },
  { label: 'Premium', value: VideoType.premium },
];
