import { yupResolver } from '@hookform/resolvers/yup';
import { Collapse } from 'antd';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputField } from '@/components/molecules/InputField';
import { RichTextEditor } from '@/components/molecules/RichTextEditor';
import { SelectCategory } from '@/components/molecules/SelectCategory';
import { SelectTag } from '@/components/molecules/SelectTag';
import { TableTagCell } from '@/components/molecules/TableTagCell';
import { UploadField } from '@/components/molecules/UploadField';
import { USER_ID } from '@/constants';
import { ArticleStatus } from '@/models';
import { useCreateArticle } from '@/modules/article/hooks/useCreateArticle';
import { useGetArticleDetail } from '@/modules/article/hooks/useGetArticleDetail';
import { useUpdateArticle } from '@/modules/article/hooks/useUpdateArticle';
import { FormArticleModel } from '@/modules/article/models/form';
import { getArticleFormSchema } from '@/modules/article/services/validation';
import { convertStringToSlug, history, localGetItem, stringToFileUploadModel } from '@/utils';

const defaultValues: FormArticleModel = {
  content: '',
  lead: '',
  seo_keywords: '',
  seo_title: '',
  status: ArticleStatus.publish,
  tags: [],
  thumbnail: {},
  title: '',
};

const ArticleModal: FC = () => {
  const [statusArticle, setStatusArticle] = useState<ArticleStatus>(ArticleStatus.publish);
  const { id } = useParams();
  const { mutateAsync: createArticle } = useCreateArticle();
  const { mutateAsync: updateArticleById } = useUpdateArticle();
  const { data: article } = useGetArticleDetail(id);
  const userId = localGetItem(USER_ID);
  const resolver = yupResolver(getArticleFormSchema());
  const formMethods = useForm<FormArticleModel>({ defaultValues, resolver });
  const action = id
    ? (formData: FormArticleModel) => updateArticleById({ id, ...formData })
    : createArticle;
  const onSubmit: SubmitHandler<FormArticleModel> = async (formData) => {
    console.log(123);
    const slug = convertStringToSlug(id ? article?.slug || '' : formData.title, !!id);
    const res = await action({
      user_id: userId,
      ...formData,
      slug,
      status: statusArticle,
      tags: formData?.tags?.map((tag: any) => ({ id: tag })),
    });
    if (!res) return;
    formMethods.reset();
    history.push(`/article`);
  };

  useEffect(() => {
    if (!article) return;

    formMethods.reset({
      cate_id: article.cate_id,
      content: article.content,
      lead: article.lead,
      seo_keywords: article.seo_keywords,
      seo_title: article.seo_title,
      tags: article.tags?.map((tag) => tag.id!),
      thumbnail: stringToFileUploadModel(article.thumbnail),
      title: article.title,
    });
  }, [article]);

  function handleHide() {
    formMethods.reset();
    history.push(`/article`);
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="p-4">
        <div className="d-flex align-items-center mb-8">
          <h2 className="fw-bolder fs-1 m-0 me-6">{id ? 'Chỉnh sửa' : 'Tạo'} bài viết</h2>
          {id && (
            <TableTagCell
              className={article?.status === ArticleStatus.publish ? '' : 'text-dark'}
              color={article?.status === ArticleStatus.publish ? 'success' : 'warning'}
            >
              {article?.status === ArticleStatus.publish ? 'Công khai' : 'Bản nháp'}
            </TableTagCell>
          )}
        </div>
        <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
          <UploadField
            accept={{ 'image/*': [] }}
            control={formMethods.control}
            groupClass="fv-row mb-7"
            label={<b>Thumbnail</b>}
            name="thumbnail"
            type="avatar"
            isOutlineAvatar
            required
          />

          <InputField
            autoComplete={false}
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Tiêu đề</b>}
            name="title"
            required
          />
          <Collapse className="mb-8">
            <Collapse.Panel
              key={1}
              header={
                <div>
                  <strong>Thêm thông tin</strong>
                </div>
              }
            >
              <InputField
                autoComplete={false}
                control={formMethods.control}
                groupClass="mb-7 fv-row"
                label={<b>Mô tả ngắn</b>}
                name="lead"
                required
              />

              <SelectTag
                control={formMethods.control}
                getOptionValue={(option) => option.id}
                groupClass="mb-7 fv-row w-100"
                label={<b>Tag</b>}
                name="tags"
                multiple
              />

              <SelectCategory
                control={formMethods.control}
                getOptionValue={(option) => option.id}
                groupClass="mb-7 fv-row"
                label={<b>Danh mục</b>}
                name="cate_id"
              />

              <InputField
                autoComplete={false}
                control={formMethods.control}
                groupClass="mb-7 fv-row"
                label={<b>Từ Khóa SEO</b>}
                name="seo_keywords"
                required
              />

              <InputField
                autoComplete={false}
                control={formMethods.control}
                groupClass="mb-7 fv-row"
                label={<b>Tiêu đề SEO</b>}
                name="seo_title"
                required
              />
            </Collapse.Panel>
          </Collapse>

          <RichTextEditor
            className="mw-1000"
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            height={1000}
            label={<b>Nội dung</b>}
            name="content"
            required
          />

          <div className="text-center pt-15 d-flex">
            <Button className="w-50" onClick={handleHide} variant="light">
              Hủy
            </Button>

            <Button
              className="w-50 mx-3 text-dark"
              onClick={() => setStatusArticle(ArticleStatus.draft)}
              type="submit"
              variant="warning"
            >
              Lưu nháp
            </Button>

            <Button className="w-50" type="submit" value="Tag" variant="primary">
              {!id ? 'Đăng bài' : 'Cập nhật'}
            </Button>
          </div>
        </FormField>
      </div>
    </div>
  );
};

export { ArticleModal };
