import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { PageLink, PageTitle } from '@/_metronic/layout/core';
import { Content } from '@/modules/course/components/course-detail/content/Content';
import { CourseDetail } from '@/modules/course/components/course-detail/CourseDetail';
import { FeedBack } from '@/modules/course/components/course-detail/feed-back/FeedBack';
import { Overview } from '@/modules/course/components/course-detail/overview/Overview';
import { StudentListShow } from '@/modules/course/components/course-detail/student/components/student-list/StudentList';
import { CourseListWrapper } from '@/modules/course/components/course-list/CourseList';
import { LabelListWrapper } from '@/modules/course/components/label-list/LabelList';
import { LevelListWrapper } from '@/modules/course/components/level-list/LevelList';
import { CourseModal } from '@/modules/course/components/modals/CourseModal';
import { SkillListWrapper } from '@/modules/course/components/skill-list/SkillList';

const CoursePage = () => {
  const Breadcrumbs: Array<PageLink> = [
    {
      isActive: false,
      isSeparator: false,
      path: '/course',
      title: 'Quản lý khóa học',
    },
    {
      isActive: false,
      isSeparator: true,
      path: '',
      title: '',
    },
  ];

  return (
    <Routes>
      <Route element={<Outlet />} />

      {/* course list */}
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Danh sách khóa học</PageTitle>
              <CourseListWrapper />
            </>
          }
          path="/"
        />
      </Route>

      {/* level */}
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Quản lý level</PageTitle>
              <LevelListWrapper />
            </>
          }
          path="/level"
        />
        <Route element={<Navigate to="/course/level" />} />
      </Route>

      {/* label */}
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Quản lý label</PageTitle>
              <LabelListWrapper />
            </>
          }
          path="/label"
        />
        <Route element={<Navigate to="/course" />} />
      </Route>

      {/* skill */}
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Quản lý skill</PageTitle>
              <SkillListWrapper />
            </>
          }
          path="/skill"
        />
        <Route element={<Navigate to="/course" />} />
      </Route>

      {/* overview */}
      <Route
        element={
          <>
            <CourseDetail />
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Thông tin tổng quan</PageTitle>
              <Overview />
            </>
          }
          path="/:id/overview"
        />
        <Route element={<Navigate to="/course/:id/overview" />} />
      </Route>

      {/* content */}
      <Route
        element={
          <>
            <CourseDetail />
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Nội dung</PageTitle>
              <Content />
            </>
          }
          path="/:id/content"
        />
        <Route element={<Navigate to="/course/:id/content" />} />
      </Route>

      {/* content */}
      <Route
        element={
          <>
            <CourseDetail />
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Danh sách học viên</PageTitle>
              <StudentListShow />
            </>
          }
          path="/:id/list-student"
        />
        <Route element={<Navigate to="/course/:id/list-student" />} />
      </Route>

      {/* feedback */}
      <Route
        element={
          <>
            <CourseDetail />
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Ý kiến học viên</PageTitle>
              <FeedBack />
            </>
          }
          path="/:id/feedback"
        />
        <Route element={<Navigate to="/course/:id/feedback" />} />
      </Route>

      {/* update article */}
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Chỉnh sửa khóa học</PageTitle>
              <CourseModal />
            </>
          }
          path="/:id/update"
        />
        <Route element={<Navigate to="/course/:id/update" />} />
      </Route>

      {/* create */}
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Tạo khóa học</PageTitle>
              <CourseModal />
            </>
          }
          path="/create"
        />
        <Route element={<Navigate to="/course/create" />} />
      </Route>
    </Routes>
  );
};

export default CoursePage;
