import ApexCharts, { ApexOptions } from 'apexcharts';
import React, { useEffect, useRef } from 'react';

interface ChartProps {
  seriesData: {
    dates: string[];
    prices: number[];
  };
}

const ChartVisiter: React.FC<ChartProps> = ({ seriesData }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options: ApexOptions = {
      chart: {
        height: 350,
        toolbar: {
          show: false,
        },
        type: 'area',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: seriesData.dates,
      legend: {
        horizontalAlign: 'left',
      },
      series: [
        {
          data: seriesData.prices,
          name: 'STOCK ABC',
        },
      ],
      stroke: {
        curve: 'straight',
      },
      subtitle: {
        align: 'left',
        text: 'Price Movements',
      },
      title: {
        align: 'left',
        text: 'Fundamental Analysis of Stocks',
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        opposite: true,
      },
    };

    if (chartRef.current) {
      const chart = new ApexCharts(chartRef.current, options);
      chart.render();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.innerHTML = '';
      }
    };
  }, [seriesData]);

  return <div ref={chartRef} id="chart" />;
};

export { ChartVisiter };
