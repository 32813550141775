import { useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { QueryOptions } from '@/models';
import { TagModel } from '@/modules/article/models/response';

function endpoint() {
  return `/article/tag/list`;
}

export function useGetTagList(options?: QueryOptions<TagModel[], unknown, TagModel[], string[]>) {
  const { handleCallApi } = useCallApi();

  function handler() {
    return handleCallApi(() => axiosClient.get<TagModel[]>(endpoint()));
  }

  return useQuery(['tag-label-list'], handler, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });
}
