import { useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { ID, MutationOptions } from '@/models';
import { fakeNetworkResponse } from '@/utils';

function endpoint(id: ID) {
  return `/course/delete/${id}`;
}

export function useDeleteCourse(options?: MutationOptions<boolean, ID>) {
  const { handleCallApi } = useCallApi();
  const deleteMessage = 'Xóa bản ghi thành công';

  function handler(id: ID) {
    return handleCallApi(
      async () => {
        await axiosClient.delete(endpoint(id));

        return fakeNetworkResponse(true);
      },
      true,
      deleteMessage
    );
  }

  return useMutation(handler, options);
}
