import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { PageLink, PageTitle } from '@/_metronic/layout/core';
import { SystemListWrapper } from '@/modules/system/components/system-list/SystemList';

const SystemPage = () => {
  const Breadcrumbs: Array<PageLink> = [
    {
      isActive: false,
      isSeparator: false,
      path: '/system',
      title: 'Quản lý SEO',
    },
    {
      isActive: false,
      isSeparator: true,
      path: '',
      title: '',
    },
  ];

  return (
    <Routes>
      <Route element={<Outlet />} />
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Danh sach SEO</PageTitle>
              <SystemListWrapper />
            </>
          }
          path="/"
        />
        <Route element={<Navigate to="/system" />} />
      </Route>
    </Routes>
  );
};

export default SystemPage;
