import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputColorField } from '@/components/molecules/InputColorField';
import { InputField } from '@/components/molecules/InputField';
import { Modal } from '@/components/organisms/Modal';
import { ID } from '@/models';
import { useCreateLabel } from '@/modules/course/hooks/label/useCreateLabel';
import { useGetLabelDetail } from '@/modules/course/hooks/label/useGetLabelDetail';
import { useUpdateLabel } from '@/modules/course/hooks/label/useUpdateLabel';
import { FormLabelModal } from '@/modules/course/models';
import { getLabelFormSchema } from '@/modules/course/services/validation';

interface IProps {
  readonly labelId?: ID;
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly show?: boolean;
}

const defaultValues: FormLabelModal = {
  color: '',
  name: '',
};

const LabelModal: FC<IProps> = ({ show, onHide, labelId, onSaved }) => {
  const { mutateAsync: createLabel } = useCreateLabel();

  const { mutateAsync: updateLabelById } = useUpdateLabel();

  const resolver = yupResolver(getLabelFormSchema());
  const formMethods = useForm<FormLabelModal>({ defaultValues, resolver });
  const action = labelId
    ? (formData: FormLabelModal) => updateLabelById({ id: labelId, ...formData })
    : createLabel;
  const onSubmit: SubmitHandler<FormLabelModal> = async (formData) => {
    const res = await action(formData);
    if (!res) return;
    onSaved?.();
    onHide?.();
    formMethods.reset();
  };

  useGetLabelDetail(labelId, {
    enabled: !!labelId,
    onSuccess: (label) => {
      if (!label) return;

      formMethods.reset({
        color: label.color,
        name: label.name,
      });
    },
  });

  function handleHide() {
    onHide?.();
    formMethods.reset();
  }

  return (
    <Modal
      backdrop={'static'}
      bodyClassName="scroll-y"
      dialogClassName="mw-850px"
      onHide={handleHide}
      show={show}
      title={<h2 className="fw-bolder">{labelId ? 'Chỉnh sửa' : 'Tạo'} nhãn</h2>}
      centered
    >
      <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
        <InputField
          autoComplete={false}
          control={formMethods.control}
          groupClass="mb-7 fv-row"
          label={<b>Tên nhãn</b>}
          name="name"
          required
        />

        <InputColorField
          control={formMethods.control}
          label={<b>Màu nền: </b>}
          labelClass="w-100"
          name="color"
        />

        <div className="text-center pt-15 d-flex">
          <Button className="me-3 w-50" onClick={handleHide} variant="light">
            Hủy
          </Button>

          <Button className="w-50" type="submit" variant="primary">
            {labelId ? 'Cập nhật' : 'Thêm mới'}
          </Button>
        </div>
      </FormField>
    </Modal>
  );
};

export { LabelModal };
