import { size } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { KTCard, KTCardBody } from '@/_metronic/helpers';
import { TableGlobalSearch } from '@/components/molecules/TableGlobalSearch';
import { TableListGrouping } from '@/components/molecules/TableListGrouping';
import { Table } from '@/components/organisms/Table';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { useTable } from '@/hooks/useTable';
import { getColumnDef } from '@/modules/course/components/course-detail/student/components/student-list/_columns';
import { StudentFilter } from '@/modules/course/components/course-detail/student/components/student-list/StudentListFilter';
import { useGetStudentByCourseId } from '@/modules/course/hooks/useGetStudentByCourseId';
import { StudentFilterModel, StudentModel } from '@/modules/student/models';

const StudentList = () => {
  const { id } = useParams();
  const {
    queryState,
    updateQueryState,
    updateSortStateByTable,
    getTableSortState,
    updateFiltersState,
    globalSearch,
    handleUpdateGlobalSearch,
  } = useQueryRequest<StudentFilterModel>();

  const { table, ...tableProps } = useTable<StudentModel>(getTableSortState);
  const columnDef = useMemo(() => getColumnDef(tableProps.columnResizeMode), []);
  const customerToDelete = useMemo(
    () => table?.getSelectedRowModel().flatRows.map(({ original }) => original),
    [table]
  );
  const { data, isLoading, isFetching } = useGetStudentByCourseId(id);

  //filter user bought course
  const student = data?.rows?.filter((object) => object.count_courses > 0);

  useEffect(() => {
    updateSortStateByTable(tableProps.sorting);
  }, [tableProps.sorting]);

  function handleOnFiltersConfirm(formData: Partial<StudentFilterModel>) {
    const updates: StudentFilterModel = {
      ...formData,
      name: formData.name || '',
      phone_number: formData.phone_number || '',
    };
    updateFiltersState(updates);
  }

  return (
    <KTCard>
      <div className="card-header border-0 pt-6">
        <TableGlobalSearch onChange={handleUpdateGlobalSearch} value={globalSearch} />
        <div className="card-toolbar">
          {(() => {
            if (size(tableProps.rowSelection) > 0) {
              return <TableListGrouping keyText={'user_name'} selected={customerToDelete} />;
            }
          })()}
          <StudentFilter
            initFiltersValues={queryState.filters}
            onFiltersConfirm={handleOnFiltersConfirm}
          />
        </div>
      </div>

      <KTCardBody className="py-4">
        <Table
          {...tableProps}
          columns={columnDef}
          currentPage={queryState.page}
          data={student}
          isLoading={isFetching || isLoading}
          limit={queryState.limit}
          onLimitChange={(limit) => updateQueryState({ limit })}
          onPageChange={(page) => updateQueryState({ page })}
        />
      </KTCardBody>
    </KTCard>
  );
};

const StudentListShow = () => <StudentList />;

export { StudentListShow };
