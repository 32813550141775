import { FC } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { KTSVG } from '@/_metronic/helpers';
import { useSwapGroup } from '@/modules/course/components/course-detail/content/components/course-group/hooks/useSwapGroup';
import { CourseModel } from '@/modules/course/models';

interface IProps {
  readonly courseDetail?: CourseModel;
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly show?: boolean;
}

const SortGroupModal: FC<IProps> = ({ courseDetail, onSaved }) => {
  const groupVideos = courseDetail?.group_videos;
  const { mutateAsync: swapGroupVideo } = useSwapGroup();

  async function handleOnDragEnd(result: DropResult) {
    const { source, destination } = result;

    if (groupVideos?.length && destination?.index) {
      await swapGroupVideo({
        form1: {
          id: groupVideos[source.index].id,
          order: groupVideos[destination.index].order,
        },
        form2: {
          id: groupVideos[destination.index].id,
          order: groupVideos[source.index].order,
        },
      });
      onSaved?.();
    }
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="groupVideo">
          {(provided) => (
            <div className="groupVideo" {...provided.droppableProps} ref={provided.innerRef}>
              {groupVideos?.map((groupVideo, indexGroupVideo) => (
                <Draggable
                  key={groupVideo.id}
                  draggableId={groupVideo.id || ''}
                  index={indexGroupVideo}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      key={indexGroupVideo}
                      role="button"
                    >
                      <div className="w-100 position-relative m-4 p-5 rounded-3 bg-secondary fs-2 d-flex aline-item-center shadow bg-body rounded">
                        <div className="pe-2 pb-2">
                          <KTSVG path="/media/icons/duotune/general/drag.svg" />
                        </div>
                        <span> {groupVideo.name}</span>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export { SortGroupModal };
