import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { PageLink, PageTitle } from '@/_metronic/layout/core';
import { ArticleListWrapper } from '@/modules/article/components/article-list/ArticleList';
import { ArticleModal } from '@/modules/article/components/ArticleModal';
import { ArticleOverView } from '@/modules/article/components/ArticleOverView';
import { CategoryListWrapper } from '@/modules/article/components/category-list/CategoryList';
import { TagListWrapper } from '@/modules/article/components/tag-list/TagList';

const ArticlePage = () => {
  const Breadcrumbs: Array<PageLink> = [
    {
      isActive: false,
      isSeparator: false,
      path: '/article',
      title: 'Quản lý bài viết',
    },
    {
      isActive: false,
      isSeparator: true,
      path: '',
      title: '',
    },
  ];

  return (
    <Routes>
      <Route element={<Outlet />} />
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Danh sách bài viết</PageTitle>
              <ArticleListWrapper />
            </>
          }
          path="/"
        />
        <Route element={<Navigate to="/posts" />} />
      </Route>

      {/* Category */}
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Danh sách danh mục</PageTitle>
              <CategoryListWrapper />
            </>
          }
          path="/categorys"
        />
        <Route element={<Navigate to="/posts/categorys" />} />
      </Route>

      {/* tag */}
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Danh sách tag</PageTitle>
              <TagListWrapper />
            </>
          }
          path="/tags"
        />
        <Route element={<Navigate to="/posts/tags" />} />
      </Route>

      {/* view post */}
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>xem bài viết</PageTitle>
              <ArticleOverView />
            </>
          }
          path="/:id/overview"
        />
        <Route element={<Navigate to="/posts/:id/overview" />} />
      </Route>

      {/* update post */}
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Chỉnh sửa bài viết</PageTitle>
              <ArticleModal />
            </>
          }
          path="/:id/update"
        />
        <Route element={<Navigate to="/posts/:id/update" />} />
      </Route>

      {/* create */}
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Tạo bài viết</PageTitle>
              <ArticleModal />
            </>
          }
          path="/create"
        />
        <Route element={<Navigate to="/posts/create" />} />
      </Route>
    </Routes>
  );
};

export default ArticlePage;
