import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { KTSVG } from '@/_metronic/helpers';
import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputField } from '@/components/molecules/InputField';
import { ID } from '@/models';
import {
  useDeleteGroup,
  useUpdateGroup,
} from '@/modules/course/components/course-detail/content/components/course-group/hooks';
import { FormCourseGroupModal } from '@/modules/course/models';
import { getGroupVideoFormSchema } from '@/modules/course/services/validation';

interface IProps {
  readonly className?: string;
  readonly groupName?: string;
  readonly groupVideoID?: ID;
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly order?: number;
}

const UpdateGroupModal: FC<IProps> = ({ groupVideoID, groupName, onSaved, order, onHide }) => {
  const { mutateAsync: updateGroupVideo } = useUpdateGroup();
  const { mutateAsync: deleteGroupVideo } = useDeleteGroup();

  const { id } = useParams();
  const resolver = yupResolver(getGroupVideoFormSchema());
  const formMethods = useForm<FormCourseGroupModal>({ resolver });

  const onSubmit: SubmitHandler<FormCourseGroupModal> = async (formData) => {
    const res = await updateGroupVideo({ course_id: id, id: groupVideoID, order, ...formData });

    if (!res) return;

    onSaved?.();
    onHide?.();
    formMethods.reset();
  };

  useEffect(() => {
    formMethods.reset({
      name: groupName,
    });
  }, [groupName]);

  return (
    <FormField
      className="w-100 d-flex"
      classNameFieldset="w-100"
      methods={formMethods}
      onSubmit={onSubmit}
    >
      <div className="w-100 d-flex justify-content-between">
        <InputField
          className="w-100 mt-1"
          control={formMethods.control}
          groupClass="w-50"
          name="name"
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
        <div className="d-flex">
          <Button
            className="ms-2 h-auto bg-light"
            onClick={async (event) => {
              event.stopPropagation();
              await deleteGroupVideo(groupVideoID);
              onSaved?.();
              formMethods.reset();
            }}
          >
            <KTSVG className="m-0" path="/media/icons/duotune/general/delete.svg" />
          </Button>
          <Button
            className="ms-2 h-auto bg-light"
            onClick={(event) => {
              event.stopPropagation();
            }}
            type="submit"
          >
            <KTSVG className="m-0" path="/media/icons/duotune/general/check.svg" />
          </Button>
          <Button
            className="ms-2 h-auto bg-light"
            onClick={(event) => {
              onHide?.();
              formMethods.reset();
              event.stopPropagation();
            }}
          >
            <KTSVG className="m-0" path="/media/icons/duotune/general/xmark.svg" />
          </Button>
        </div>
      </div>
    </FormField>
  );
};

export { UpdateGroupModal };
