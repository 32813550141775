import { Outlet, Route, Routes } from 'react-router-dom';

import { PageTitle } from '@/_metronic/layout/core';
import { Overview } from '@/modules/report/component/ReportOverView';

const ReportPage = () => {
  return (
    <>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            element={
              <>
                <PageTitle breadcrumbs={[]}>{'Dashboard'}</PageTitle>
                <Overview />
              </>
            }
            index
          />
        </Route>
      </Routes>
    </>
  );
};

export default ReportPage;
