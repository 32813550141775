import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputField } from '@/components/molecules/InputField';
import { InputMoneyField } from '@/components/molecules/InputMoneyField';
import { InputNumberField } from '@/components/molecules/InputNumberField';
import { SelectField } from '@/components/molecules/SelectField';
import { Modal } from '@/components/organisms/Modal';
import { ID } from '@/models';
import { dataSystem } from '@/modules/system/components/system-list/SystemList';
import { useCreateEmployee } from '@/modules/system/hooks/useCreateEmployee';
import { useUpdateEmployee } from '@/modules/system/hooks/useUpdateEmployee';
import { FormSystemModel } from '@/modules/system/models/form';
import { getEmployeeFormSchema } from '@/modules/system/services/validation';

interface IProps {
  readonly employeeId?: ID;
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly show?: boolean;
}

const defaultValues: FormSystemModel = {
  category: ' ',
  level: 0,
  name_course: ' ',
  number_of_lessons: 0,
  number_of_student: 0,
  price: 0,
  status: ' ',
};

const EmployeeModal: FC<IProps> = ({ show, onHide, employeeId, onSaved }) => {
  const { mutateAsync: createEmployee } = useCreateEmployee();
  const { mutateAsync: updateEmployeeById } = useUpdateEmployee();

  const resolver = yupResolver(getEmployeeFormSchema());
  const formMethods = useForm<FormSystemModel>({ defaultValues, resolver });
  const action = employeeId
    ? (formData: FormSystemModel) => updateEmployeeById({ form: formData, id: employeeId })
    : createEmployee;
  const { mutateAsync } = useMutation(action);
  const onSubmit: SubmitHandler<FormSystemModel> = async (formData) => {
    const res = await mutateAsync(formData);
    if (!res) return;
    onSaved?.();
    formMethods.reset();
  };

  useEffect(() => {
    formMethods.reset({
      category: dataSystem[0].category,
      level: dataSystem[0].level,
      name_course: dataSystem[0].name_course,
      number_of_lessons: dataSystem[0].number_of_lessons,
      number_of_student: dataSystem[0].number_of_student,
      price: dataSystem[0].price,
      status: dataSystem[0].status,
    });
  }, [dataSystem]);

  function handleHide() {
    onHide?.();
    formMethods.reset();
  }

  return (
    <div data-testid="add-employee-modal">
      <Modal
        backdrop={'static'}
        bodyClassName="scroll-y"
        dialogClassName="mw-850px"
        onHide={handleHide}
        show={show}
        title={<h2 className="fw-bolder">chỉnh sửa khóa học</h2>}
        centered
      >
        <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
          <InputField
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Tên khóa học</b>}
            name="name_course"
            required
          />

          <InputField
            autoComplete={false}
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Danh mục</b>}
            name="category"
            required
          />

          <InputNumberField
            autoComplete={false}
            className="w-100"
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Cấp độ</b>}
            name="level"
            required
          />

          <InputMoneyField
            autoComplete={false}
            className="w-100"
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Giá tiền</b>}
            name="price"
            required
          />

          <InputNumberField
            autoComplete={false}
            className="w-100"
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Số học viên</b>}
            name="number_of_student"
            required
          />

          <SelectField
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>trạng thái</b>}
            name="status"
            required
          />

          <InputNumberField
            autoComplete={false}
            className="w-100"
            control={formMethods.control}
            groupClass="mb-7 fv-row"
            label={<b>Số bài</b>}
            name="number_of_lessons"
            required
          />

          <div className="text-center pt-15 d-flex">
            <Button className="me-3 w-50" onClick={handleHide} variant="light">
              Hủy
            </Button>

            <Button className="w-50" type="submit" variant="primary">
              Thêm
            </Button>
          </div>
        </FormField>
      </Modal>
    </div>
  );
};

export { EmployeeModal };
