import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputField } from '@/components/molecules/InputField';
import { InputTimeField } from '@/components/molecules/InputTimeField';
import { SelectField } from '@/components/molecules/SelectField';
import { DateTimeFormat } from '@/constants';
import { ID, VideoType } from '@/models';
import {
  useCreateVideo,
  useDeleteVideo,
  useUpdateVideo,
} from '@/modules/course/components/course-detail/content/components/course-video/hooks';
import {
  CourseGroupModal,
  FormCourseVideoModal,
  VIDEO_TYPE_OPTION,
  VideoModal,
} from '@/modules/course/models';
import { getVideoFormSchema } from '@/modules/course/services/validation';

interface IProps {
  readonly courseID?: ID;
  readonly groupVideo?: CourseGroupModal;
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly videoDetail?: VideoModal;
}

const defaultValues: FormCourseVideoModal = {
  name: '',
  order: 0,
  total_time: '',
  type: VideoType.premium,
  video_url: '',
};

const CourseVideoModal: FC<IProps> = ({ onSaved, videoDetail, courseID, groupVideo, onHide }) => {
  const { mutateAsync: createVideo } = useCreateVideo();
  const { mutateAsync: updateVideo } = useUpdateVideo();
  const { mutateAsync: deleteVideo } = useDeleteVideo();

  const getLastVideoOrder = useMemo(() => {
    return groupVideo?.videos.length ? (groupVideo?.videos.at(-1)?.order || 0) + 1 : 1;
  }, [groupVideo?.videos.length]);

  const getCurrentVideoOrder = useMemo(() => {
    return groupVideo?.videos.find((video) => video.id === videoDetail?.id);
  }, [videoDetail]);
  const resolver = yupResolver(getVideoFormSchema());
  const formMethods = useForm<FormCourseVideoModal>({ defaultValues, resolver });
  const action = videoDetail
    ? (formData: FormCourseVideoModal) =>
        updateVideo({ id: videoDetail.id, ...formData, order: getCurrentVideoOrder?.order || 0 })
    : (formData: FormCourseVideoModal) =>
        createVideo({
          ...formData,
          order: getLastVideoOrder,
        });

  const onSubmit: SubmitHandler<FormCourseVideoModal> = async (formData) => {
    const res = await action({
      ...formData,
      course_id: courseID,
      group_id: groupVideo?.id,
    });
    if (!res) return;

    onSaved?.();
    onHide?.();
    formMethods.reset();
  };

  useEffect(() => {
    formMethods.reset({
      name: videoDetail?.name,
      order: videoDetail?.order,
      total_time: videoDetail?.total_time,
      type: videoDetail?.type,
      video_url: videoDetail?.video_url,
    });
  }, [videoDetail]);

  function handleHide() {
    onSaved?.();
    onHide?.();
    formMethods.reset();
  }

  const handleDeleteVideo = async () => {
    await deleteVideo(videoDetail?.id);
    onSaved?.();
    onHide?.();
    formMethods.reset();
  };

  return (
    <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
      <InputField
        autoComplete={false}
        control={formMethods.control}
        groupClass="mb-7 fv-row"
        label={<b>Tiêu đề</b>}
        name="name"
        required
      />

      <InputTimeField
        control={formMethods.control}
        formatValue={DateTimeFormat.full_time}
        groupClass="mb-7 fv-row"
        label={<b>Độ dài video</b>}
        name="total_time"
        showNow={false}
        autoComplete
        required
      />

      <SelectField
        control={formMethods.control}
        getOptionValue={(option) => option.value}
        groupClass="mb-7 fv-row"
        label={<b>Kiểu</b>}
        name="type"
        options={VIDEO_TYPE_OPTION}
        required
      />

      <InputField
        className="w-100"
        control={formMethods.control}
        groupClass="mb-7 fv-row"
        label={<b>Link Video</b>}
        name="video_url"
        required
      />

      <div className="text-center pt-15 d-flex">
        <Button className="me-3 w-100" onClick={handleHide} variant="light">
          Hủy
        </Button>

        <Button className="me-3 w-100" type="submit" variant="primary">
          {videoDetail ? 'Cập nhật' : 'Thêm'}
        </Button>

        {videoDetail && (
          <Button className="w-100" onClick={handleDeleteVideo} variant="danger">
            xóa
          </Button>
        )}
      </div>
    </FormField>
  );
};

export { CourseVideoModal };
