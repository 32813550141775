import { QueryClient, useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { FormCategoryModal } from '@/modules/article/models/form';
import { fakeNetworkResponse, prepareDataToRequest } from '@/utils';

function endpoint() {
  return `/article/category/update`;
}

export function useUpdateCategory(options?: MutationOptions<boolean, FormCategoryModal>) {
  const { handleCallApi } = useCallApi();
  const queryClient = new QueryClient();
  const updateMessage = 'Cập nhật bản ghi thành công';

  function handler(form: FormCategoryModal) {
    return handleCallApi(
      async () => {
        await axiosClient.patch(endpoint(), prepareDataToRequest(form));
        queryClient.invalidateQueries({ queryKey: ['category-list'] });

        return fakeNetworkResponse(true);
      },
      true,
      updateMessage
    );
  }

  return useMutation(handler, options);
}
