/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import '@/_metronic/assets/css/style.rtl.css'
 **/
import 'antd/dist/antd.less';
import '@/_metronic/assets/sass/plugins.scss';
import '@/_metronic/assets/sass/style.react.scss';
import '@/_metronic/assets/sass/style.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Chart, registerables } from 'chart.js';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider as ReduxProvider } from 'react-redux';

import { IS_TESTING_MODE } from '@/constants';
import { worker } from '@/mocks/browser';
import { ErrorFallback } from '@/modules/errors/ErrorsFallback';
import { AppRoutes } from '@/router/AppRoutes';
import { store } from '@/store';

if (IS_TESTING_MODE) {
  worker.start();
}

Chart.register(...registerables);

const queryClient = new QueryClient();
const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <AppRoutes />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
}
