import { rest } from 'msw';

import { loginMock, profileMock } from '@/mocks/data/user';

const baseAPIUrl = import.meta.env.VITE_BASE_API_URL;
const url = (endpoint: string): string => `${baseAPIUrl}/${endpoint}`;

// Define handlers that catch the corresponding requests and returns the mock data.
export const handlers = [
  rest.post(url(`login`), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(loginMock));
  }),

  rest.get(url(`profile`), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(profileMock));
  }),
];
