import { Button, Image } from 'antd';
import { Markup } from 'interweave';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { KTSVG } from '@/_metronic/helpers';
import { TableTagCell } from '@/components/molecules/TableTagCell';
import { OverviewModal } from '@/modules/course/components/course-detail/overview/OverviewModal';
import { useGetCourseDetail } from '@/modules/course/hooks/useGetCourseDetail';
import { formatMoney, history } from '@/utils';

const Overview: React.FC = () => {
  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false);
  const { id } = useParams();
  const { data: courseDetail } = useGetCourseDetail(id);

  return (
    <>
      <div className="card mb-5 mb-xl-10">
        <div className="card-body p-9 ">
          <div className="d-flex w-100 border border-mute border-2 rounded-4 p-5">
            <div className="d-flex w-100">
              <Image
                className="rounded-4 object-fit-contain"
                height={280}
                preview={false}
                src={
                  courseDetail?.thumbnail
                    ? courseDetail?.thumbnail
                    : '/public/media/gif/spinner.gif'
                }
                style={{ objectFit: 'contain' }}
              />
              <div className="ps-8 w-100">
                <h1
                  className="d-flex align-items-start justify-content-between"
                  style={{ fontSize: '30px' }}
                >
                  {courseDetail?.name}
                  <Button
                    onClick={() => history.push(`/course/${courseDetail?.id}/update`)}
                    size="large"
                  >
                    <Image preview={false} src="/media/icons/duotune/art/art005.svg" width={20} />
                  </Button>
                </h1>
                <div className="d-flex align-items-center py-3">
                  <h5 className="m-0 pe-2">Trạng thái: </h5>
                  <TableTagCell
                    className="fs-5"
                    color={`${courseDetail?.status === 'ACTIVE' ? 'success' : 'warning'}`}
                  >
                    {courseDetail?.status}
                  </TableTagCell>
                </div>
                <hr className="border-top border-1 border-dark" />
                <div className="d-flex flex-column w-100">
                  <div>
                    <div className="fs-4 align-items-center d-flex justify-content-between text-left">
                      <strong>Yêu cầu các kĩ năng</strong>
                    </div>
                    <div className="d-flex flex-sm-wrap w-100">
                      {courseDetail?.require_skills &&
                        courseDetail?.require_skills.map((requireSkill) => (
                          <div key={requireSkill.id} className="w-50 pt-3 d-flex">
                            <span className="pe-4">
                              <KTSVG path="/media/icons/duotune/general/check.svg" />
                            </span>
                            {requireSkill.name}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-8">
                    <h5 className="m-0 pe-2">Cấp độ: </h5>
                    {courseDetail?.levels &&
                      courseDetail?.levels.map((level) => (
                        <TableTagCell key={level.id} className="fs-5 mx-1" color={`primary`}>
                          {level.name}
                        </TableTagCell>
                      ))}
                  </div>
                  <div
                    className="mt-4 d-flex align-items-center"
                    onClick={() => setIsVisibleFormModal(true)}
                  >
                    <Image
                      className="cursor-pointer"
                      preview={false}
                      src="/media/icons/duotune/general/movie.svg"
                      width={25}
                    />
                    <span className="ps-2 fs-6 text-underline-hover cursor-pointer">
                      Video giới thiệu
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between w-100 border border-mute border-2 rounded-4 p-5 mt-5">
            <div className="d-flex align-items-center mb-3">
              <h3 className="fs-2">Giá khóa học:</h3>
              <h2 className="text-primary fs-1 ps-2 pb-2">
                {courseDetail?.price || 0 <= 1 ? (
                  <TableTagCell color="success">Free</TableTagCell>
                ) : (
                  formatMoney(courseDetail?.price)
                )}
              </h2>
            </div>
            <h1 className="fs-2">Mô Tả khóa học</h1>
            <span className="fs-5">
              <Markup content={courseDetail?.description} />
            </span>
          </div>
        </div>
      </div>
      {isVisibleFormModal && (
        <OverviewModal
          onHide={() => {
            setIsVisibleFormModal(false);
          }}
          show={isVisibleFormModal}
          urlVideo={courseDetail?.video_intro}
        />
      )}
    </>
  );
};

export { Overview };
