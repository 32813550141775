import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { PageLink, PageTitle } from '@/_metronic/layout/core';
import { OrderListWrapper } from '@/modules/order/components/order-list/OrderList';

const OrderPage = () => {
  const Breadcrumbs: Array<PageLink> = [
    {
      isActive: false,
      isSeparator: false,
      path: '/order',
      title: 'Quản lý đơn hàng',
    },
    {
      isActive: false,
      isSeparator: true,
      path: '',
      title: '',
    },
  ];

  return (
    <Routes>
      <Route element={<Outlet />} />
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Danh sach đơn hàng</PageTitle>
              <OrderListWrapper />
            </>
          }
          path="/"
        />
        <Route element={<Navigate to="/order" />} />
      </Route>
    </Routes>
  );
};

export default OrderPage;
