import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';
import { FC } from 'react';

interface IProps {
  readonly className?: string;
  readonly size?: SpinSize;
}

const Loading: FC<IProps> = ({ className, size }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return <Spin className={className} indicator={antIcon} size={size} />;
};

export { Loading };
