import { Image } from 'antd';
import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { Login } from '@/modules/auth/components/Login';

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body');

    return () => {
      document.body.classList.remove('bg-body');
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        background: '#F7F9FF',
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        <a className="mb-12" href="#">
          <Image
            preview={false}
            src={toAbsoluteUrl('/media/icons/duotune/general/logo.svg')}
            width={100}
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div
          className="w-lg-500px p-10 p-lg-15 mx-auto"
          style={{
            backgroundColor: '#fff',
            border: '1px solid rgba(91, 105, 135, 0.22)',
            borderRadius: '24px',
            boxShadow: 'rgba(11, 43, 158, 0.15) 0px 6px 20px -6px',
          }}
        >
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/* end::Footer */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route element={<Login />} path="login" />
      <Route element={<Login />} index />

      {/* Page Not Found */}
      <Route element={<Navigate to="/error/404" />} path="*" />
    </Route>
  </Routes>
);

export { AuthPage };
