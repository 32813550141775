import { useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { FormSkillModal } from '@/modules/course/models';
import { fakeNetworkResponse, prepareDataToRequest } from '@/utils';

function endpoint() {
  return `/course/skill/add`;
}

export function useCreateSkill(options?: MutationOptions<boolean, FormSkillModal>) {
  const { handleCallApi } = useCallApi();
  const createMessage = 'Tạo mới bản ghi thành công';

  function handler(form: FormSkillModal) {
    return handleCallApi(
      async () => {
        await axiosClient.post(endpoint(), prepareDataToRequest(form));

        return fakeNetworkResponse(true);
      },
      true,
      createMessage
    );
  }

  return useMutation(handler, options);
}
