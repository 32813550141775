import { StudentRole, StudentStatus } from '@/modules/student/models/response';

export interface FormStudentModel {
  email: string;
  full_name: string;
  phone: string;
  role: StudentRole;
  status: StudentStatus;
}

export const STUDENT_ROLE = [
  { label: 'admin', value: StudentRole.admin },
  { label: 'member', value: StudentRole.member },
];

export const STUDENT_STATUS = [
  { label: 'active', value: StudentStatus.active },
  { label: 'block', value: StudentStatus.block },
];
