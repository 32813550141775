import * as Yup from 'yup';

import { useValidation } from '@/hooks/useValidation';
import { Shape } from '@/models';
import { FormSystemModel } from '@/modules/system/models';

const { stringRequired } = useValidation('.EMPLOYEE');

function getEmployeeFormSchema() {
  return Yup.object<Shape<FormSystemModel>>({
    category: stringRequired('EMAIL'),
    level: stringRequired('PHONE_NUMBER'),
    name_course: stringRequired('EMAIL'),
    number_of_lessons: stringRequired('PHONE_NUMBER'),
    number_of_student: stringRequired('PHONE_NUMBER'),
    price: stringRequired('PHONE_NUMBER'),
    status: stringRequired('EMAIL'),
  });
}

export { getEmployeeFormSchema };
