import { QueryClient, useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { FormCourseVideoModal } from '@/modules/course/models';
import { fakeNetworkResponse, prepareDataToRequest } from '@/utils';

function endpoint() {
  return `/course/video/add`;
}

export function useCreateVideo(options?: MutationOptions<boolean, FormCourseVideoModal>) {
  const { handleCallApi } = useCallApi();
  const queryClient = new QueryClient();
  const createMessage = 'Tạo mới bản ghi thành công';

  function handler(form: FormCourseVideoModal) {
    return handleCallApi(
      async () => {
        await axiosClient.post(endpoint(), prepareDataToRequest(form));
        queryClient.invalidateQueries({ queryKey: ['course-list'] });

        return fakeNetworkResponse(true);
      },
      true,
      createMessage
    );
  }

  return useMutation(handler, options);
}
