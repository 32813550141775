import { AuthModel, INetworkResponse, UserModel, UserType } from '@/models';
import { UserStatus } from '@/models/auth';

export const userMock: UserModel = {
  avatar:
    'https://api.microlap.vn//upload-service/files//589350fb-4525-11ed-b2a5-0242ac1c0002.jpeg',
  country_code: '',
  created_at: '2022-10-04T06:57:43.835301Z',
  email: 'ư',
  full_name: 'ADMIN',
  id: 'd87775f0-43b1-11ed-8683-0242c0a8b002',
  password: '',
  phone_number: 'quochit879@gmail.com',
  role: 'admin',
  status: UserStatus.active,
  updated_at: '2022-10-06T03:17:52.500729Z',
  user_name: 'quochit879@gmail.com',
  user_type: UserType.employee,
};

export const loginMock: INetworkResponse<AuthModel> = {
  data: {
    ...userMock,
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiJkODc3NzVmMC00M2IxLTExZWQtODY4My0wMjQyYzBhOGIwMDIiLCJVc2VyVHlwZSI6IkVNUExPWUVFIiwiZXhwIjoxNjY1OTM5NzIwfQ.2eeyhwC1atQcbR2ugcbEpKgO-J-g_-FE3uZMJ22Fuac',
  },
};

export const profileMock: INetworkResponse<UserModel> = { data: { ...userMock } };
