import clsx from 'clsx';
import { FC } from 'react';

import { KTSVG } from '@/_metronic/helpers';
import { useLayout } from '@/_metronic/layout/core';
import { HeaderUserMenu } from '@/_metronic/partials';
import { AvatarGroup } from '@/components/molecules/AvatarGroup';
import { useAuth } from '@/hooks/useAuth';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const Topbar: FC = () => {
  const { config } = useLayout();
  const { currentUser } = useAuth();

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-attach="parent"
          data-kt-menu-flip="bottom"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-trigger="click"
        >
          {currentUser && (
            <div className={clsx('d-flex justify-content-center')}>
              <AvatarGroup
                isImageRoundedCircle={false}
                user={currentUser}
                isHiddenDescription
                isHiddenName
              />
            </div>
          )}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className="d-flex align-items-center d-lg-none ms-2 me-n3" title="Show header menu">
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG className="svg-icon-1" path="/media/icons/duotune/text/txt001.svg" />
          </div>
        </div>
      )}
    </div>
  );
};

export { Topbar };
