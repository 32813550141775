import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { PageLink, PageTitle } from '@/_metronic/layout/core';
import { StudentListWrapper } from '@/modules/student/components/student-list/StudentList';

const StudentPage = () => {
  const Breadcrumbs: Array<PageLink> = [
    {
      isActive: false,
      isSeparator: false,
      path: '/student',
      title: 'Quản lý học viên',
    },
    {
      isActive: false,
      isSeparator: true,
      path: '',
      title: '',
    },
  ];

  return (
    <Routes>
      <Route element={<Outlet />} />
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Danh sách học viên</PageTitle>
              <StudentListWrapper />
            </>
          }
          path="/"
        />
        <Route element={<Navigate to="/student" />} />
      </Route>
    </Routes>
  );
};

export default StudentPage;
