import { QueryClient, useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { FormArticleModel } from '@/modules/article/models/form';
import { fakeNetworkResponse, prepareDataToRequest } from '@/utils';

function endpoint() {
  return `/article/add`;
}

export function useCreateArticle(options?: MutationOptions<boolean, FormArticleModel>) {
  const { handleCallApi } = useCallApi();
  const queryClient = new QueryClient();
  const createMessage = 'Tạo mới bản ghi thành công';

  function handler(form: FormArticleModel) {
    return handleCallApi(
      async () => {
        await axiosClient.post(endpoint(), prepareDataToRequest(form));
        queryClient.invalidateQueries({ queryKey: ['post-list'] });

        return fakeNetworkResponse(true);
      },
      true,
      createMessage
    );
  }

  return useMutation(handler, options);
}
