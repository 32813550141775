import { size } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import { KTCard, KTCardBody } from '@/_metronic/helpers';
import { TableGlobalSearch } from '@/components/molecules/TableGlobalSearch';
import { TableListGrouping } from '@/components/molecules/TableListGrouping';
import { Table } from '@/components/organisms/Table';
import { useGetCache } from '@/hooks/useGetCache';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { useTable } from '@/hooks/useTable';
import { TypeClear } from '@/models/clearCache';
import { getColumnDef } from '@/modules/article/components/article-list/_columns';
import { useDeleteArticle } from '@/modules/article/hooks/useDeleteArticle';
import { useGetArticleList } from '@/modules/article/hooks/useGetArticleList';
import { ArticleFilterModel } from '@/modules/article/models/filters';
import { ArticleModel } from '@/modules/article/models/response';
import { CourseListToolbar } from '@/modules/course/components/course-list/CourseListToolbar';
import { history } from '@/utils';

const ArticleList = () => {
  const {
    queryState,
    updateQueryState,
    updateSortStateByTable,
    getTableSortState,
    queryString,
    globalSearch,
    handleUpdateGlobalSearch,
  } = useQueryRequest<ArticleFilterModel>();

  const { ...tableProps } = useTable<ArticleModel>(getTableSortState);
  const columnDef = useMemo(
    () =>
      getColumnDef(
        tableProps.columnResizeMode,
        handleClickEdit,
        handleClickRemove,
        handleClickClear
      ),
    []
  );

  const { data, isLoading, isFetching, refetch } = useGetArticleList(queryString);
  const { mutateAsync: deleteArticleById } = useDeleteArticle();
  const { mutateAsync: clearCache } = useGetCache();

  useEffect(() => {
    updateSortStateByTable(tableProps.sorting);
  }, [tableProps.sorting]);

  function handleClickEdit(article: ArticleModel) {
    history.push(`article/${article.id}/update`);
  }

  function handleClickClear(article: ArticleModel) {
    clearCache({ slug: article.slug, type: TypeClear.article });
  }

  async function handleClickRemove(article: ArticleModel) {
    const response = await deleteArticleById(article.id);
    response && refetch();
  }

  return (
    <>
      <KTCard>
        <div className="card-header border-0 pt-6">
          <TableGlobalSearch onChange={handleUpdateGlobalSearch} value={globalSearch} />
          <div className="card-toolbar">
            {(() => {
              if (size(tableProps.rowSelection) > 0) {
                return <TableListGrouping keyText={'title'} />;
              }

              return (
                <CourseListToolbar onClickCreateButton={() => history.push(`article/create`)} />
              );
            })()}
          </div>
        </div>

        <KTCardBody className="py-4">
          <Table
            {...tableProps}
            columns={columnDef}
            currentPage={queryState.page}
            data={data?.rows}
            isLoading={isFetching || isLoading}
            limit={queryState.limit}
            onLimitChange={(limit) => updateQueryState({ limit })}
            onPageChange={(page) => updateQueryState({ page })}
            total={data?.total_rows}
          />
        </KTCardBody>
      </KTCard>
    </>
  );
};

const ArticleListWrapper = () => <ArticleList />;

export { ArticleListWrapper };
