import { QueryClient, useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { FormCategoryModal } from '@/modules/article/models/form';
import { fakeNetworkResponse, prepareDataToRequest } from '@/utils';

function endpoint() {
  return `/article/category/add`;
}

export function useCreateCategory(options?: MutationOptions<boolean, FormCategoryModal>) {
  const { handleCallApi } = useCallApi();
  const queryClient = new QueryClient();
  const createMessage = 'Tạo mới bản ghi thành công';

  function handler(form: FormCategoryModal) {
    return handleCallApi(
      async () => {
        await axiosClient.post(endpoint(), prepareDataToRequest(form));
        queryClient.invalidateQueries({ queryKey: ['category-list'] });

        return fakeNetworkResponse(true);
      },
      true,
      createMessage
    );
  }

  return useMutation(handler, options);
}
