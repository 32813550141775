import { useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { FormSwapGroupModal } from '@/modules/course/models';
import { fakeNetworkResponse, prepareDataToRequest } from '@/utils';

function endpoint() {
  return '/course/group-video/swap';
}

export function useSwapGroup(
  options?: MutationOptions<boolean, { form1: FormSwapGroupModal; form2: FormSwapGroupModal }>
) {
  const { handleCallApi } = useCallApi();
  const updateMessage = 'Cập nhật bản ghi thành công';

  function handler({ form1, form2 }: { form1: FormSwapGroupModal; form2: FormSwapGroupModal }) {
    return handleCallApi(
      async () => {
        await axiosClient.patch(
          endpoint(),
          prepareDataToRequest([
            {
              id: form1.id,
              order: form1.order,
            },
            {
              id: form2.id,
              order: form2.order,
            },
          ])
        );

        return fakeNetworkResponse(true);
      },
      true,
      updateMessage
    );
  }

  return useMutation(handler, options);
}
