export const enum StudentStatus {
  active = 'ACTIVE',
  block = 'BLOCK',
}

export const enum StudentRole {
  admin = 'ADMIN',
  member = 'MEMBER',
}

export interface StudentModel {
  amount_received: number;
  count_courses: number;
  created_at: string;
  email: string;
  full_name: string;
  id: string;
  number_of_course: number;
  permission: string;
  phone: string;
  role: StudentRole;
  score: number;
  status: StudentStatus;
}
