import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputField } from '@/components/molecules/InputField';
import { Modal } from '@/components/organisms/Modal';
import { useCreateCategory } from '@/modules/article/hooks/useCategory/useCreateCategory';
import { useUpdateCategory } from '@/modules/article/hooks/useCategory/useUpdateCategory';
import { FormCategoryModal } from '@/modules/article/models/form';
import { CategoryModel } from '@/modules/article/models/response';
import { getLabelFormSchema } from '@/modules/course/services/validation';
import { convertStringToSlug } from '@/utils';

interface IProps {
  readonly category?: CategoryModel;
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly show?: boolean;
}

const defaultValues: FormCategoryModal = {
  name: '',
  slug: '',
};

const CategoryModal: FC<IProps> = ({ show, onHide, category, onSaved }) => {
  const { mutateAsync: createCategory } = useCreateCategory();

  const { mutateAsync: updateCategoryById } = useUpdateCategory();

  const resolver = yupResolver(getLabelFormSchema());
  const formMethods = useForm<FormCategoryModal>({ defaultValues, resolver });
  const action = category
    ? (formData: FormCategoryModal) => updateCategoryById({ id: category.id, ...formData })
    : createCategory;
  const onSubmit: SubmitHandler<FormCategoryModal> = async (formData) => {
    const slug = convertStringToSlug(category?.slug ? category?.slug : formData.name, !!category);
    const res = await action({ ...formData, slug });
    if (!res) return;
    onSaved?.();
    onHide?.();
    formMethods.reset();
  };

  useEffect(() => {
    formMethods.reset({
      name: category?.name,
    });
  }, [category]);

  function handleHide() {
    onHide?.();
    formMethods.reset();
  }

  return (
    <Modal
      backdrop={'static'}
      bodyClassName="scroll-y"
      dialogClassName="mw-850px"
      onHide={handleHide}
      show={show}
      title={<h2 className="fw-bolder">{category ? 'Chỉnh sửa' : 'Tạo'} danh mục</h2>}
      centered
    >
      <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
        <InputField
          autoComplete={false}
          control={formMethods.control}
          groupClass="mb-7 fv-row"
          label={<b>Danh mục</b>}
          name="name"
          required
        />

        <div className="text-center pt-15 d-flex">
          <Button className="me-3 w-50" onClick={handleHide} variant="light">
            Hủy
          </Button>

          <Button className="w-50" type="submit" variant="primary">
            {category ? 'Cập nhật' : 'Thêm mới'}
          </Button>
        </div>
      </FormField>
    </Modal>
  );
};

export { CategoryModal };
