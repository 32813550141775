import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputField } from '@/components/molecules/InputField';
import { useAuth } from '@/hooks/useAuth';
import { FormLoginModel } from '@/models';
import { getLoginFormSchema } from '@/modules/auth/services/validation';

const defaultValues: FormLoginModel = {
  email: '',
  password: '',
};

export function Login() {
  const { login, isLoading } = useAuth();
  const resolver = yupResolver(getLoginFormSchema());
  const formMethods = useForm<FormLoginModel>({ defaultValues, resolver });

  const onSubmit: SubmitHandler<FormLoginModel> = (formData) => {
    login(formData);
  };

  return (
    <FormField className="w-100" methods={formMethods} onSubmit={onSubmit}>
      <div className="text-center mb-10">
        <h1 className="text-primary mb-3 fs-2" style={{ fontFamily: 'Epilogue' }}>
          Đăng nhập Microlap
        </h1>
      </div>

      <InputField
        autoComplete={false}
        className="rounded rounded"
        control={formMethods.control}
        groupClass="mb-5"
        label={
          <span style={{ borderRadius: '4px', color: '#0069FF', fontFamily: 'JetBrains Mono' }}>
            Email
          </span>
        }
        name="email"
        placeholder="email"
        required
      />

      <InputField
        autoComplete={false}
        className="rounded rounded text-dark"
        control={formMethods.control}
        groupClass="mb-5"
        label={<span style={{ color: '#0069FF', fontFamily: 'JetBrains Mono' }}>Mật khẩu</span>}
        name="password"
        placeholder="mật khẩu"
        style={{ borderColor: '#e4e4e4' }}
        type="password"
        required
      />

      <div className="text-center">
        <Button
          className="my-5 w-100"
          color="#0056d2"
          isLoading={isLoading}
          style={{ backgroundColor: '#0056d2', fontFamily: 'Epilogue' }}
          type="submit"
        >
          Đăng nhập
        </Button>
      </div>
    </FormField>
  );
}
