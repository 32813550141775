import { QueryClient, useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { ID, MutationOptions } from '@/models';
import { fakeNetworkResponse } from '@/utils';

function endpoint() {
  return `/delete`;
}

export function useDeleteEmployee(options?: MutationOptions<boolean, string[]>) {
  const { handleCallApi } = useCallApi();
  const queryClient = new QueryClient();
  const deleteMessage = 'Xóa bản ghi thành công';

  function handler(ids: ID[]) {
    return handleCallApi(
      async () => {
        await axiosClient.delete(endpoint(), { data: { ids } });
        queryClient.invalidateQueries({ queryKey: ['system'] });

        return fakeNetworkResponse(true);
      },
      true,
      deleteMessage
    );
  }

  return useMutation(handler, options);
}
