import { useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { QueryOptions } from '@/models';
import { CategoryModel } from '@/models/category';

function endpoint() {
  return `/article/category/list`;
}

export function useGetCategoryList(
  options?: QueryOptions<CategoryModel[], unknown, CategoryModel[], string[]>
) {
  const { handleCallApi } = useCallApi();

  function handler() {
    return handleCallApi(() => axiosClient.get<CategoryModel[]>(endpoint()));
  }

  return useQuery(['category-list'], handler, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });
}
