import { Button, Image } from 'antd';
import clsx from 'clsx';
import { Markup } from 'interweave';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Title } from '@/_metronic/layout/core';
import { Loading } from '@/components/molecules/Loading';
import { TableTagCell } from '@/components/molecules/TableTagCell';
import { useGetArticleDetail } from '@/modules/article/hooks/useGetArticleDetail';
import { getTimeFormat, history } from '@/utils';

const ArticleOverView: React.FC = () => {
  const { id } = useParams();
  const { data: articleDetail, isLoading } = useGetArticleDetail(id);

  function handleClickEdit() {
    history.push(`update`);
  }

  return (
    <>
      <div className="card mb-5 mb-xl-10">
        {isLoading ? (
          <Loading className={clsx(!isLoading && 'd-none')} />
        ) : (
          <div className="p-4">
            <h1 className="d-flex justify-content-between align-items-center fw-bold fs-1 pt-4">
              <Markup content={articleDetail?.title} />{' '}
              <Button onClick={handleClickEdit} size="large">
                <Image preview={false} src="/media/icons/duotune/art/art005.svg" width={20} />
              </Button>
            </h1>
            <div className="mt-3 d-flex">
              {articleDetail?.tags?.map((tag) => (
                <TableTagCell
                  key={tag.id}
                  backgroundColor={tag.color || '#999'}
                  className="fs-5 mx-1"
                >
                  # {tag.name}
                </TableTagCell>
              ))}
            </div>
            <div className="d-flex flex-column fs-4 mt-3">
              {getTimeFormat(articleDetail?.created_at)}
              <span>Tác giả: {articleDetail?.author?.full_name}</span>
            </div>
            <hr className="border-top border-1 border-dark" />
            <div className="mt-3 fs-2">
              <Markup content={articleDetail?.content} />
            </div>
          </div>
        )}
      </div>
      <Title>{articleDetail?.title}</Title>
    </>
  );
};

export { ArticleOverView };
