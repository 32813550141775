import { ColumnDef, ColumnResizeMode, createColumnHelper } from '@tanstack/react-table';
import { Image } from 'antd';

import { TableActionCellFlatStyle } from '@/components/molecules/TableActionCellFlatStyle';
import { TableHeaderCell } from '@/components/molecules/TableHeaderCell';
import { TableTagCell } from '@/components/molecules/TableTagCell';
import { TableVisibilityColumnCell } from '@/components/molecules/TableVisibilityColumnCell';
import { CourseModel } from '@/modules/course/models';
import { formatMoney, getTimeFormat, history } from '@/utils';

function getColumnDef(
  columnResizeMode: ColumnResizeMode = 'onChange',
  onClickEdit?: (data: CourseModel) => void,
  onClickRemove?: (data: CourseModel) => void,
  onClickClear?: (data: CourseModel) => void
): ColumnDef<CourseModel, any>[] {
  const columnHelper = createColumnHelper<CourseModel>();

  return [
    columnHelper.accessor('thumbnail', {
      cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <Image
            className="rounded-1"
            height={50}
            preview={false}
            src={row.original.thumbnail ? row.original.thumbnail : '/public/media/gif/spinner.gif'}
          />
        </div>
      ),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-40px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="thumbnail"
        />
      ),
    }),

    columnHelper.accessor('name', {
      cell: ({ row }) => {
        return (
          <span
            className="text-underline-hover cursor-pointer"
            onClick={() => {
              history.push(`course/${row.original.id}/overview`);
            }}
            style={{ whiteSpace: 'break-spaces' }}
          >
            {row.original.name}
          </span>
        );
      },
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="mw-80px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Tên khóa học"
        />
      ),
    }),

    columnHelper.accessor('price', {
      cell: ({ row }) => {
        const price = row.original.price;
        if (price <= 1) {
          return <TableTagCell color="success">Free</TableTagCell>;
        }

        return formatMoney(price);
      },
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-40px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="giá"
        />
      ),
    }),

    columnHelper.accessor('code', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-40px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="code"
        />
      ),
    }),

    columnHelper.accessor('status', {
      cell: ({ row }) => {
        const isActive = row.original.status;

        return (
          <TableTagCell color={isActive === 'ACTIVE' ? 'success' : 'warning'} isLight>
            {isActive === 'ACTIVE' ? 'Đã kích hoạt' : 'Chưa kích hoạt'}
          </TableTagCell>
        );
      },
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-40px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="trạng thái"
        />
      ),
    }),

    columnHelper.accessor('count_student', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-40px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="số học viên"
        />
      ),
    }),

    columnHelper.accessor('created_at', {
      cell: ({ row }) => getTimeFormat(row.original.created_at),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-50px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Ngày tạo"
        />
      ),
    }),

    columnHelper.accessor((row) => row.id, {
      cell: ({ row }) => (
        <TableActionCellFlatStyle
          data={row.original}
          isShowViewButton={false}
          onClickClear={onClickClear}
          onClickEdit={onClickEdit}
          onClickRemove={onClickRemove}
          isShowClearButton
        />
      ),
      enableHiding: false,
      enableResizing: false,
      enableSorting: false,
      header: ({ header, table }) => (
        <TableHeaderCell className="w-50px" columnResizeMode={columnResizeMode} header={header}>
          <TableVisibilityColumnCell
            className="justify-content-end"
            menuClassName="text-primary"
            table={table}
            title="tác vụ"
          />
        </TableHeaderCell>
      ),
      id: 'actions',
    }),
  ];
}

export { getColumnDef };
