import clsx from 'clsx';
import React, { MouseEventHandler, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { Control, Path, useController } from 'react-hook-form';

type Props<IForm extends object> = {
  readonly name: Path<IForm>;
  readonly className?: string;
  readonly control?: Control<IForm, object>;
  readonly disabled?: boolean;
  readonly errorClass?: string;
  readonly groupClass?: string;
  readonly hint?: ReactNode;
  readonly hintClass?: string;
  readonly label?: ReactNode;
  readonly labelClass?: string;
  readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onClick?: MouseEventHandler<HTMLElement>;
  readonly required?: boolean;
};

const InputColorField = <IForm extends object>(props: Props<IForm>) => {
  const { field, formState } = props.control
    ? useController({ control: props.control, name: props.name })
    : { field: undefined, formState: undefined };
  const errorMessage = (formState?.errors[props.name]?.message as string) || '';

  return (
    <Form.Group className={clsx(props.groupClass)}>
      <Form.Label
        className={clsx(!props.label && 'd-none', props.required && 'required', props.labelClass)}
      >
        {props.label}
      </Form.Label>
      <input
        {...field}
        className={props.className}
        onChange={(e) => {
          field?.onChange(e);
          props.onChange?.(e);
        }}
        type="color"
      />
      <Form.Text className={clsx('text-muted', props.hintClass)}>{props.hint}</Form.Text>
      <Form.Control.Feedback className={clsx(props.errorClass)} type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export { InputColorField };
