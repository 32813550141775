import { Image, Popconfirm } from 'antd';
import clsx from 'clsx';
import { CSSProperties, useEffect } from 'react';

import { MenuComponent } from '@/_metronic/assets/ts/components';
import { KTSVG, WithChildren } from '@/_metronic/helpers';
import { Button } from '@/components/molecules/Button';

interface IProps<TData> extends WithChildren {
  readonly data: TData;
  readonly ButtonText?: string;
  readonly editButtonClassName?: string;
  readonly iconClassName?: string;
  readonly isShowClearButton?: boolean;
  readonly isShowEditButton?: boolean;
  readonly isShowRemoveButton?: boolean;
  readonly isShowViewButton?: boolean;
  readonly onClickButtonText?: (data: TData) => void;
  readonly onClickClear?: (data: TData) => void;
  readonly onClickEdit?: (data: TData) => void;
  readonly onClickRemove?: (data: TData) => void;
  readonly onClickView?: (data: TData) => void;
  readonly removeButtonClassName?: string;
  readonly style?: CSSProperties;
  readonly urlIcon?: string;
  readonly variant?: string;
  readonly viewButtonClassName?: string;
}

const TableActionCellFlatStyle = <TData,>(props: IProps<TData>) => {
  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  return (
    <div className="d-flex justify-content-end">
      {props.isShowClearButton && (
        <div className="menu-item px-3">
          <Button
            className={clsx('mb-1 p-0')}
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation();
              props.onClickClear?.(props.data);
            }}
            style={props.style}
            variant={props.variant || 'link'}
          >
            <KTSVG className="svg-icon-2 m-0" path="/media/icons/duotune/general/broom.svg" />
          </Button>
        </div>
      )}
      {props.isShowEditButton && (
        <div className="menu-item px-3">
          <Button
            className={clsx('mb-1 p-0')}
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation();
              props.onClickEdit?.(props.data);
            }}
            style={props.style}
            variant={props.variant || 'link'}
          >
            <KTSVG className="svg-icon-2 m-0" path="/media/icons/duotune/general/art005.svg" />
          </Button>
        </div>
      )}

      {props.isShowRemoveButton && (
        <div className="menu-item px-3">
          <Popconfirm
            cancelText={'Hủy'}
            okButtonProps={{ danger: true }}
            okText={'Xác nhận'}
            onConfirm={() => {
              props.onClickRemove?.(props.data);
            }}
            style={props.style}
            title={'Bạn có thực sự muốn xóa bản ghi này'}
          >
            <Button className={clsx('p-0')} onClick={(e) => e.stopPropagation()} variant="link">
              <KTSVG className="svg-icon-2 m-0" path="/media/icons/duotune/general/gen027.svg" />
            </Button>
          </Popconfirm>
        </div>
      )}

      {props.isShowViewButton && (
        <div className="menu-item px-3">
          <Button
            className={clsx('mb-1 p-0', props.viewButtonClassName)}
            onClick={(e) => {
              e.stopPropagation();
              props.onClickView?.(props.data);
            }}
            style={props.style}
            variant="link"
          >
            <KTSVG className="svg-icon-2 m-0" path="/media/icons/duotune/general/gen004.svg" />
          </Button>
        </div>
      )}

      {props.ButtonText && (
        <div className="menu-item px-3">
          <Button
            className={clsx('mb-1 p-0', props.viewButtonClassName)}
            onClick={(e) => {
              e.stopPropagation();
              props.onClickButtonText?.(props.data);
            }}
            style={props.style}
            variant={props.variant || ''}
          >
            {props.urlIcon && (
              <Image
                className={props.iconClassName}
                color="susses"
                preview={false}
                src={props.urlIcon}
                width={13}
              />
            )}
            <span className="m-0 ps-2">{props.ButtonText}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

TableActionCellFlatStyle.defaultProps = {
  isShowClearButton: false,
  isShowEditButton: true,
  isShowRemoveButton: true,
  isShowViewButton: true,
};

export { TableActionCellFlatStyle };
