import { Image } from 'antd';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { SelectField } from '@/components/molecules/SelectField';
import { TableTagCell } from '@/components/molecules/TableTagCell';
import { Modal } from '@/components/organisms/Modal';
import { ID } from '@/models';
import { useGetOrderDetail } from '@/modules/order/hooks/useGetOrderDetail';
import { useUpdateOrder } from '@/modules/order/hooks/useUpdateOrder';
import { FormOrderModel, ORDER_STATUS } from '@/modules/order/models/form';
import { formatMoney } from '@/utils';

interface IProps {
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly orderId?: ID;
  readonly show?: boolean;
}

const OrderModal: FC<IProps> = ({ show, onHide, orderId, onSaved }) => {
  const { mutateAsync: updateOrder } = useUpdateOrder();
  const { data: orderDetail, isLoading } = useGetOrderDetail(orderId);
  const formMethods = useForm<FormOrderModel>();
  const onSubmit: SubmitHandler<FormOrderModel> = async (formData) => {
    const res = await updateOrder({ ...formData, order_id: orderId });
    if (!res) return;
    onSaved?.();
    formMethods.reset();
  };

  useEffect(() => {
    formMethods.reset({
      status: orderDetail?.status,
    });
  }, [orderDetail]);

  function handleHide() {
    onHide?.();
    formMethods.reset();
  }

  return (
    <div data-testid="add-employee-modal">
      <Modal
        backdrop={isLoading || 'static'}
        bodyClassName="scroll-y"
        dialogClassName="mw-1000px"
        onHide={handleHide}
        show={show}
        title={
          <div className="w-50 d-flex align-items-center">
            <h2 className="w-75 fw-bolder">Duyệt đơn hàng</h2>
            <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
              <SelectField
                control={formMethods.control}
                getOptionValue={(option) => option.value}
                groupClass="fv-row"
                name="status"
                options={ORDER_STATUS}
                required
              />
            </FormField>
          </div>
        }
        centered
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column w-50" style={{ color: '#666' }}>
            <h1 className="pb-3 text-primary">Người mua</h1>
            <div className="row mb-2">
              <label className="col-lg-4 fw-bold text-muted">Họ tên: </label>

              <div className="col-lg-8">
                <span className="fw-bolder fs-6">{orderDetail?.user_info.full_name}</span>
              </div>
            </div>
            <div className="row mb-2">
              <label className="col-lg-4 fw-bold text-muted">Email: </label>

              <div className="col-lg-8">
                <span className="fw-bolder fs-6">{orderDetail?.user_info.email}</span>
              </div>
            </div>

            <div className="row mb-2">
              <label className="col-lg-4 fw-bold text-muted">Sdt: </label>

              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 me-2">{orderDetail?.user_info.phone}</span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">Số khóa học đã mua:</label>

              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 me-2">
                  {orderDetail?.user_info.number_of_course || 0}
                </span>
              </div>
            </div>
          </div>
          <div className="w-50">
            <h1 className="pb-3 text-primary">Khóa học</h1>
            <div className="d-flex">
              <Image preview={false} src={orderDetail?.course_info.thumbnail} width={100} />
              <div className="d-flex flex-column ps-5 fs-2">
                <h1 className="fs-2 text-dark">{orderDetail?.course_info.name}</h1>
                <div>
                  <strong className="text-muted">Giá: </strong>
                  <strong>{formatMoney(orderDetail?.course_info.price || 0)}</strong>
                </div>
                <div>
                  <strong className="fs-6 text-muted">Trạng thái:</strong>
                  <TableTagCell
                    className="w-25"
                    color={orderDetail?.course_info.status === 'ACTIVE' ? 'success' : 'warning'}
                    isLight
                  >
                    {orderDetail?.course_info.status === 'ACTIVE'
                      ? 'Đã kích hoạt'
                      : 'Chưa kích hoạt'}
                  </TableTagCell>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
          <div className="text-center pt-15 d-flex">
            <Button className="me-3 w-50" onClick={handleHide} variant="light">
              Hủy
            </Button>

            <Button className="w-50" type="submit" variant="primary">
              Xác nhận
            </Button>
          </div>
        </FormField>
      </Modal>
    </div>
  );
};

export { OrderModal };
