import { useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { FormStudentModel } from '@/modules/student/models';
import { fakeNetworkResponse, prepareDataToRequest } from '@/utils';

function endpoint() {
  return `/user/update`;
}

export function useUpdateStudent(options?: MutationOptions<boolean, FormStudentModel>) {
  const { handleCallApi } = useCallApi();
  const updateMessage = 'Cập nhật bản ghi thành công';

  function handler(form: FormStudentModel) {
    return handleCallApi(
      async () => {
        await axiosClient.patch(endpoint(), prepareDataToRequest(form));

        return fakeNetworkResponse(true);
      },
      true,
      updateMessage
    );
  }

  return useMutation(handler, options);
}
