import { Image } from 'antd';
import clsx from 'clsx';
import { FC, useState } from 'react';
import ReactPlayer from 'react-player';

import { Modal } from '@/components/organisms/Modal';

interface IProps {
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly show?: boolean;
  readonly urlVideo?: string;
}

const OverviewModal: FC<IProps> = ({ show, onHide, urlVideo }) => {
  const [loadingVideo, setLoadingVideo] = useState<boolean>(true);
  function handleHide() {
    onHide?.();
  }

  return (
    <Modal
      dialogClassName="mw-700px"
      isHeaderButtonClose={false}
      onHide={handleHide}
      show={show}
      title={<strong className="w-100 fs-1 text-center">Video giới thiệu</strong>}
      centered
    >
      <div className="my-6 w-100 d-flex justify-content-start rounded-1 overflow-hidden">
        {loadingVideo && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '360px', width: '620px' }}
          >
            <Image preview={false} src="/public/media/gif/spinner.gif" width={80} />
          </div>
        )}
        <div className={clsx(`${loadingVideo ? 'd-none' : 'd-block'}`)}>
          <ReactPlayer
            height="360px"
            onBuffer={() => setLoadingVideo(false)}
            onReady={() => setLoadingVideo(false)}
            style={{ borderRadius: '10px', overflow: 'hidden' }}
            url={urlVideo}
            width="640px"
            controls
            playing
          />
        </div>
      </div>
    </Modal>
  );
};

export { OverviewModal };
