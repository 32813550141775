import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputField } from '@/components/molecules/InputField';
import { ID } from '@/models';
import { useCreateGroup } from '@/modules/course/components/course-detail/content/components/course-group/hooks';
import { FormCourseGroupModal } from '@/modules/course/models';
import { getGroupVideoFormSchema } from '@/modules/course/services/validation';

interface IProps {
  readonly customerId?: ID;
  readonly groupVideos?: FormCourseGroupModal[];
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly show?: boolean;
}

const CreateGroupModal: FC<IProps> = ({ groupVideos, onHide, onSaved }) => {
  const { id } = useParams();
  const resolver = yupResolver(getGroupVideoFormSchema());
  const formMethods = useForm<FormCourseGroupModal>({ resolver });

  const { mutateAsync: CreateGroupVideo } = useCreateGroup();

  const { mutateAsync } = useMutation(CreateGroupVideo);

  const onSubmit: SubmitHandler<FormCourseGroupModal> = async (formData) => {
    const res = await mutateAsync({
      course_id: id,
      order: groupVideos?.length ? groupVideos?.length + 1 : 1,
      ...formData,
    });
    if (!res) return;
    onSaved?.();
    formMethods.reset();
  };

  function handleHide() {
    onHide?.();
    formMethods.reset();
  }

  return (
    <FormField className="form w-100 rounded" methods={formMethods} onSubmit={onSubmit}>
      <InputField
        autoComplete={false}
        control={formMethods.control}
        groupClass="mb-7 fv-row"
        label={<b>Tiêu đề</b>}
        name="name"
        required
      />

      <div className="text-center pt-15 d-flex">
        <Button className="me-3 w-50" onClick={handleHide} variant="light">
          Hủy
        </Button>

        <Button className="w-50" type="submit" variant="primary">
          Thêm
        </Button>
      </div>
    </FormField>
  );
};

export { CreateGroupModal };
