import { useMutation } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { MutationOptions } from '@/models';
import { FormCourseVideoModal } from '@/modules/course/models';
import { fakeNetworkResponse, prepareDataToRequest } from '@/utils';

function endpoint() {
  return '/course/video/update';
}

export function useUpdateVideo(options?: MutationOptions<boolean, FormCourseVideoModal>) {
  const { handleCallApi } = useCallApi();
  const updateMessage = 'Cập nhật bản ghi thành công';

  function handler(form: FormCourseVideoModal) {
    return handleCallApi(
      async () => {
        await axiosClient.patch(endpoint(), prepareDataToRequest(form));

        return fakeNetworkResponse(true);
      },
      true,
      updateMessage
    );
  }

  return useMutation(handler, options);
}
