import { useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { QUERIES } from '@/constants/query';
import { useCallApi } from '@/hooks/useCallApi';
import { PaginationResponse, QueryOptions } from '@/models';
import { FormSystemModel } from '@/modules/system/models';

function endpoint(query?: string) {
  return `list?${query || ''}`;
}

export function useGetEmployeeList(
  query?: string,
  options?: QueryOptions<
    PaginationResponse<FormSystemModel[]>,
    unknown,
    PaginationResponse<FormSystemModel[]>,
    (string | undefined)[]
  >
) {
  const { handleCallApi } = useCallApi();

  function handler() {
    return handleCallApi(() =>
      axiosClient.get<PaginationResponse<FormSystemModel[]>>(endpoint(query))
    );
  }

  return useQuery([QUERIES.DRIVER_LIST, query], handler, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });
}
