import ApexCharts from 'apexcharts';
import { FC, useEffect, useRef } from 'react';

import { formatMoney } from '@/utils';

type IProps = {
  readonly time: number;
};

const ChartMoneyUser: FC<IProps> = ({ time }) => {
  const chartRef = useRef(null);
  console.log(time);

  const data = [
    {
      date: '1/12/2022',
      money: 1211111,
      user: 10,
    },
    {
      date: '2/12/2022',
      money: 5033333,
      user: 12,
    },
    {
      date: '3/12/2022',
      money: 122200,
      user: 11,
    },
    {
      date: '4/12/2022',
      money: 303333,
      user: 22,
    },
    {
      date: '5/12/2022',
      money: 304440,
      user: 10,
    },
    {
      date: '6/12/2022',
      money: 503330,
      user: 105,
    },
    {
      date: '7/12/2022',
      money: 155552,
      user: 123,
    },
  ];

  useEffect(() => {
    const options = {
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: data.map((value) => value.date),
      series: [
        {
          data: data.map((value) => value.user),
          name: 'Người dùng',
          type: 'column',
        },
        {
          data: data.map((value) => value.money),
          formatter: (value: number) => {
            return formatMoney(value);
          },
          name: 'Tiền',
          type: 'line',
        },
      ],
      stroke: {
        width: [0, 4],
      },
      title: {
        text: 'Thống kê doanh thu',
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: [
        {
          title: {
            text: 'Số người dùng',
          },
        },
        {
          labels: {
            formatter: (value: number) => formatMoney(value),
          },
          opposite: true,
          title: {
            text: 'Doanh thu',
          },
        },
      ],
    };
    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, []);

  return <div ref={chartRef} className="pt-10" id="chart" />;
};

export { ChartMoneyUser };
