const QUERIES = {
  ACTIVE_PROVINCE_LIST: 'active-province-list',

  CATALOGUE_SERVICE_BANK_LIST: 'catalogue-service-bank-list',
  CATALOGUE_SERVICE_DETAILS: 'catalogue-service-details',
  CATALOGUE_SERVICE_LIST: 'catalogue-service-list',

  CUSTOMER_DETAILS: 'customer-details',
  CUSTOMER_LIST: 'customer-list',

  DISTRICT_LIST: 'district-list',

  DRIVER_DETAILS: 'driver-details',
  DRIVER_LIST: 'driver-list',
  DRIVER_ONLINE_LIST: 'driver-online-list',

  DRIVER_WALLET_DETAIL: 'driver-wallet-detail',
  DRIVER_WALLET_LIST: 'driver-wallet-list',

  EMPLOYEE_DETAILS: 'employee-details',
  EMPLOYEE_LIST: 'employee-list',

  EMPLOYEE_TYPE_LIST: 'employee-type-list',

  FORCE_UPDATE_LIST: 'force-update-list',

  ORDER_DETAILS: 'order-details',
  ORDER_LIST: 'order-list',

  PROFILE_DETAILS: 'profile-details',

  PROMOTION_DETAILS: 'promotion-details',

  PROMOTION_LIST: 'promotion-list',
  PROVINCE_LIST: 'province-list',

  REFERRAL_LIST: 'referral-list',

  SERVICE_LIST: 'service-list',

  STATISTICS_DRIVER: 'statistics-driver',

  STATISTICS_ORDER: 'statistics-order',
  WALLET_CASHOUT_REQUEST_LIST: 'wallet_cashout_request_list',

  WARD_LIST: 'ward-list',
};

export { QUERIES };
