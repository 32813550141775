import { ID, StatusOrder } from '@/models';

export interface FormOrderModel {
  order_id: ID;
  status: StatusOrder;
  course_id?: ID;
}

export const ORDER_STATUS = [
  { label: 'Xác nhận', value: StatusOrder.approved },
  { label: 'Hủy đơn', value: StatusOrder.cancel },
  { label: 'Đang xử lí', value: StatusOrder.pending },
  { label: 'Từ chối', value: StatusOrder.reject },
  { label: 'Chờ xác nhận', value: StatusOrder.review },
];
