import { useEffect, useMemo, useState } from 'react';

import { KTCard, KTCardBody } from '@/_metronic/helpers';
import { TableGlobalSearch } from '@/components/molecules/TableGlobalSearch';
import { Table } from '@/components/organisms/Table';
import { useQueryRequest } from '@/hooks/useQueryRequest';
import { useTable } from '@/hooks/useTable';
import { getColumnDef } from '@/modules/article/components/category-list/_columns';
import { CategoryModal } from '@/modules/article/components/modal/CategoryModal';
import { useDeleteCategory } from '@/modules/article/hooks/useCategory/useDeleteCategory';
import { useGetCategoryList } from '@/modules/article/hooks/useCategory/useGetCategoryList';
import { CategoryFilterModel, CategoryModel } from '@/modules/article/models/response';
import { CourseListToolbar } from '@/modules/course/components/course-list/CourseListToolbar';

const CategoryList = () => {
  const [categoryToEdit, setCategoryToEdit] = useState<CategoryModel>();
  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false);
  const {
    queryState,
    updateQueryState,
    updateSortStateByTable,
    getTableSortState,
    globalSearch,
    handleUpdateGlobalSearch,
  } = useQueryRequest<CategoryFilterModel>();

  const { ...tableProps } = useTable<CategoryModel>(getTableSortState);
  const columnDef = useMemo(
    () => getColumnDef(tableProps.columnResizeMode, handleClickEdit, handleClickRemove),
    []
  );

  const { isLoading, isFetching, data, refetch } = useGetCategoryList();
  const { mutateAsync: deleteCategoryById } = useDeleteCategory();
  useEffect(() => {
    updateSortStateByTable(tableProps.sorting);
  }, [tableProps.sorting]);

  function handleClickEdit(label: CategoryModel) {
    if (!label) return;
    setCategoryToEdit(label);
    setIsVisibleFormModal(true);
  }

  async function handleClickRemove(level: CategoryModel) {
    const response = await deleteCategoryById(level.id);
    response && refetch();
  }

  function handleSaved() {
    refetch();
    setIsVisibleFormModal(false);
  }

  return (
    <>
      <KTCard>
        <div className="card-header border-0 pt-6">
          <TableGlobalSearch onChange={handleUpdateGlobalSearch} value={globalSearch} />
          <div className="card-toolbar">
            {(() => (
              <CourseListToolbar
                onClickCreateButton={() => {
                  setCategoryToEdit(undefined);
                  setIsVisibleFormModal(true);
                }}
              />
            ))()}
          </div>
        </div>

        <KTCardBody className="py-4">
          <Table
            {...tableProps}
            className="cursor-pointer"
            columns={columnDef}
            currentPage={queryState.page}
            data={data}
            isLoading={isFetching || isLoading}
            limit={queryState.limit}
            onLimitChange={(limit) => updateQueryState({ limit })}
            onPageChange={(page) => updateQueryState({ page })}
          />
        </KTCardBody>
      </KTCard>

      {isVisibleFormModal && (
        <CategoryModal
          category={categoryToEdit}
          onHide={() => {
            setIsVisibleFormModal(false);
          }}
          onSaved={handleSaved}
          show={isVisibleFormModal}
        />
      )}
    </>
  );
};

const CategoryListWrapper = () => <CategoryList />;

export { CategoryListWrapper };
