import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/molecules/Button';
import { FormField } from '@/components/molecules/FormField';
import { InputField } from '@/components/molecules/InputField';
import { Modal } from '@/components/organisms/Modal';
import { LevelModel } from '@/models/level';
import { useCreateLevel } from '@/modules/course/hooks/level/useCreateLevel';
import { useUpdateLevel } from '@/modules/course/hooks/level/useUpdateLevel';
import { FormLevelModal } from '@/modules/course/models';
import { getLevelFormSchema } from '@/modules/course/services/validation';

interface IProps {
  readonly level?: LevelModel;
  readonly onHide?: () => void;
  readonly onSaved?: () => void;
  readonly show?: boolean;
}

const defaultValues: FormLevelModal = {
  name: '',
};

const LevelModal: FC<IProps> = ({ show, onHide, level, onSaved }) => {
  const { mutateAsync: createLevel } = useCreateLevel();

  const { mutateAsync: updateLevelById } = useUpdateLevel();

  const resolver = yupResolver(getLevelFormSchema());
  const formMethods = useForm<FormLevelModal>({ defaultValues, resolver });
  const action = level
    ? (formData: FormLevelModal) => updateLevelById({ id: level.id, ...formData })
    : createLevel;
  const onSubmit: SubmitHandler<FormLevelModal> = async (formData) => {
    const res = await action(formData);
    if (!res) return;
    onSaved?.();
    onHide?.();
    formMethods.reset();
  };

  useEffect(() => {
    formMethods.reset({
      name: level?.name,
    });
  }, [level]);

  function handleHide() {
    onHide?.();
    formMethods.reset();
  }

  return (
    <Modal
      backdrop={'static'}
      bodyClassName="scroll-y"
      dialogClassName="mw-850px"
      onHide={handleHide}
      show={show}
      title={<h2 className="fw-bolder">{level ? 'Chỉnh sửa' : 'Tạo'} cấp độ</h2>}
      centered
    >
      <FormField className="form w-100" methods={formMethods} onSubmit={onSubmit}>
        <InputField
          autoComplete={false}
          control={formMethods.control}
          groupClass="mb-7 fv-row"
          label={<b>cấp độ</b>}
          name="name"
          required
        />

        <div className="text-center pt-15 d-flex">
          <Button className="me-3 w-50" onClick={handleHide} variant="light">
            Hủy
          </Button>

          <Button className="w-50" type="submit" variant="primary">
            {level ? 'Cập nhật' : 'Thêm mới'}
          </Button>
        </div>
      </FormField>
    </Modal>
  );
};

export { LevelModal };
