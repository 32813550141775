import { Image } from 'antd';
import React, { useState } from 'react';

import { KTSVG } from '@/_metronic/helpers';

const FeedBack: React.FC = () => {
  const [isReplyComment, setIsReplyComment] = useState<boolean>(false);
  function handReplyComment() {
    setIsReplyComment(!isReplyComment);
  }

  return (
    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <div className="card-body p-9 ">
        <div className="">
          <div className="d-flex">
            <div>
              <Image
                className="rounded-circle"
                preview={false}
                src="/public/media/avatars/300-5.jpg"
                width={50}
              />
            </div>
            <div
              className="d-block w-100 rounded-4 p-3 ms-3"
              style={{ backgroundColor: '#f2f3f5', letterSpacing: '1px' }}
            >
              <div>
                <strong className="fs-3 pe-3">Nguyễn Văn A</strong>
              </div>
              <div className="">
                <div>
                  <p className="py-2 m-0 fs-4" style={{ color: '#292929' }}>
                    cho e hỏi về css 1 xíu sao khi mình cho dispkay bằng block mà nó lại dài ra thêm
                    1 đoạn nhỉ trong khi display flex vẫn giữ nguyên,quên mất thuộc tính của nó r
                  </p>
                </div>
              </div>
            </div>
          </div>
          <span className="ms-20 fs-6" style={{ color: '#999', letterSpacing: '0px' }}>
            11 ngày trước
          </span>
        </div>
        <div className="ps-19 fs-4 pt-2 cursor-pointer">
          {!isReplyComment && (
            <div onClick={handReplyComment}>
              <strong className="pe-2">Xem 2 câu trả lời</strong>
              <KTSVG path="/media/icons/duotune/general/arrow_down.svg" />
            </div>
          )}
          {isReplyComment && (
            <>
              <div className="mb-2" onClick={handReplyComment}>
                <strong className="pe-2">Ẩn câu trả lời</strong>
                <KTSVG path="/media/icons/duotune/general/arrow_up.svg" />
              </div>
              <div className="d-flex pt-3 ps-6 border-start border-primary">
                <div>
                  <Image
                    className="rounded-circle"
                    preview={false}
                    src="/public/media/avatars/300-8.jpg"
                    width={50}
                  />
                </div>
                <div
                  className="w-100 rounded-4 p-3 ms-3"
                  style={{ backgroundColor: '#f2f3f5', letterSpacing: '1px' }}
                >
                  <div>
                    <strong className="fs-3">Nguyễn Văn B</strong>
                  </div>
                  <div className="">
                    <div>
                      <p className="py-2 m-0 fs-4" style={{ color: '#292929' }}>
                        inbox tôi trả lời nhé
                      </p>
                      <span className="fs-6" style={{ color: '#999', letterSpacing: '0px' }}>
                        10 ngày trước
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex pt-3 ps-6 border-start border-primary">
                <div>
                  <Image
                    className="rounded-circle"
                    preview={false}
                    src="/public/media/avatars/300-5.jpg"
                    width={50}
                  />
                </div>
                <div
                  className="w-100 rounded-4 p-3 ms-3"
                  style={{ backgroundColor: '#f2f3f5', letterSpacing: '1px' }}
                >
                  <div>
                    <strong className="fs-3">Nguyễn Văn B</strong>
                  </div>
                  <div className="">
                    <div>
                      <p className="py-2 m-0 fs-4" style={{ color: '#292929' }}>
                        ok bạn
                      </p>
                      <span className="fs-6" style={{ color: '#999', letterSpacing: '0px' }}>
                        9 ngày trước
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="d-flex mt-8">
          <div>
            <Image
              className="rounded-circle"
              preview={false}
              src="/public/media/avatars/300-9.jpg"
              width={50}
            />
          </div>
          <div
            className="w-100 rounded-4 p-3 ms-3"
            style={{ backgroundColor: '#f2f3f5', letterSpacing: '1px' }}
          >
            <div>
              <strong className="fs-3 pe-3">Nguyễn Văn A</strong>
              <span className="fs-6" style={{ color: '#999', letterSpacing: '0px' }}>
                2 giờ trước
              </span>
            </div>
            <div className="">
              <div>
                <p className="py-2 m-0 fs-4" style={{ color: '#292929' }}>
                  Bài giảng Rất hay
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FeedBack };
