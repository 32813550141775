import { Button } from 'antd';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { KTSVG } from '@/_metronic/helpers';
import { CourseMenu } from '@/modules/course/components/course-detail/content/components/CourseMenu';
import { SortGroupModal } from '@/modules/course/components/course-detail/content/components/SortGroupModal';
import { useGetCourseDetail } from '@/modules/course/hooks/useGetCourseDetail';
import { convertSecondsToHour, convertTimeToSeconds } from '@/utils';

const Content: React.FC = () => {
  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false);
  const { id } = useParams();

  const { data: courseDetail, refetch } = useGetCourseDetail(id);

  //convert time to hour
  const unit = useMemo(() => {
    let countUnit = 0;
    let totalSeconds = 0;
    courseDetail?.group_videos?.map((group) => {
      if (group.videos) {
        group.videos.map((video) => {
          if (video) {
            countUnit++;
            totalSeconds += convertTimeToSeconds(video.total_time);
          }
        });
      }
    });

    return { countUnit, totalSeconds };
  }, [courseDetail?.group_videos]);

  function handleSortGroup() {
    setIsVisibleFormModal(!isVisibleFormModal);
  }

  function handleSaved() {
    refetch();
    setIsVisibleFormModal(false);
  }

  return (
    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <div className="card-body p-9">
        {/* start header */}
        <div className="d-flex justify-content-center listStyle-none">
          <ul className="d-flex fs-3 p-0">
            <li className="list-unstyled">
              <strong>{courseDetail?.group_videos?.length || 0}</strong> chương
            </li>
            <li className="list-unstyled px-3">•</li>
            <li className="list-unstyled">
              <strong>{unit.countUnit}</strong> bài học
            </li>
            <li className="list-unstyled px-3">•</li>
            <li className="list-unstyled">
              <span>
                Thời lượng <strong>{convertSecondsToHour(unit.totalSeconds)}</strong>
              </span>
            </li>
          </ul>
        </div>
        {/* end header */}
        <div className="d-flex justify-content-end">
          {isVisibleFormModal ? (
            <Button
              className="h-50 p-3 ps-5 mb-2 btn btn-outline btn-outline-dark btn-active-light-dark"
              onClick={handleSortGroup}
            >
              <KTSVG path="/media/icons/duotune/general/check.svg" />
              Xong
            </Button>
          ) : (
            <Button
              className="h-50 py-3 mb-2 btn btn-outline btn-outline-dark btn-active-light-dark"
              onClick={handleSortGroup}
            >
              <KTSVG path="/media/icons/duotune/general/sort-solid.svg" />
              Sắp xếp
            </Button>
          )}
        </div>
        {/* start content */}
        {isVisibleFormModal ? (
          <SortGroupModal
            courseDetail={courseDetail}
            onHide={() => {
              setIsVisibleFormModal(false);
            }}
            onSaved={handleSaved}
            show={isVisibleFormModal}
          />
        ) : (
          <CourseMenu courseDetail={courseDetail} refetch={refetch} />
        )}
        {/* end content */}
      </div>
    </div>
  );
};

export { Content };
