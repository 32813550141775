import { useAppDispatch, useAppSelector } from '@/hooks';
import { MenuModel } from '@/models';
import { menuActions, selectMenuList } from '@/modules/menu/core/menuSlice';

function useMenu() {
  const dispatch = useAppDispatch();
  const menuList = useAppSelector(selectMenuList);
  const defaultMenuList: MenuModel[] = [
    // DASHBOARD
    {
      aside: { fontIcon: 'bi-app-indicator', icon: '/media/icons/duotune/art/art002.svg' },
      icon: '/media/icons/duotune/general/ic_dashboad.svg',
      title: 'Dashboard',
      to: '/dashboard',
    },

    // APPS
    {
      aside: { isContent: true },
      children: [
        //STUDENT
        {
          aside: { fontIcon: 'bi-chat-left' },
          children: [
            {
              hasBullet: true,
              title: 'Danh sách học viên',
              to: '/student',
            },
          ],
          icon: '/media/icons/duotune/general/ic_employee.svg',
          menu: {
            hasArrow: true,
            menuPlacement: 'right-start',
            menuTrigger: `{default:'click', lg: 'hover'}`,
          },
          title: 'Quản lý học viên',
          to: '/student',
        },

        //COURSE
        {
          aside: { fontIcon: 'bi-chat-left' },
          children: [
            {
              hasBullet: true,
              title: 'Danh sách khoá học',
              to: '/course',
            },
            {
              hasBullet: true,
              title: 'Quản lý cấp độ',
              to: '/course/level',
            },
            {
              hasBullet: true,
              title: 'Quản lý nhãn',
              to: '/course/label',
            },
            {
              hasBullet: true,
              title: 'Quản lý kĩ năng',
              to: '/course/skill',
            },
          ],
          icon: '/media/icons/duotune/general/courses.svg',
          menu: {
            hasArrow: true,
            menuPlacement: 'right-start',
            menuTrigger: `{default:'click', lg: 'hover'}`,
          },
          title: 'Quản lý khoá học',
          to: '/course',
        },

        //post
        {
          aside: { fontIcon: 'bi-chat-left' },
          children: [
            {
              hasBullet: true,
              title: 'Danh sách bài viết',
              to: 'article',
            },
            {
              hasBullet: true,
              title: 'Quản lý tags',
              to: 'article/tags',
            },
            {
              hasBullet: true,
              title: 'Quản lý danh mục',
              to: 'article/categorys',
            },
          ],
          icon: '/media/icons/duotune/general/post.svg',
          menu: {
            hasArrow: true,
            menuPlacement: 'right-start',
            menuTrigger: `{default:'click', lg: 'hover'}`,
          },
          title: 'Quản lý bài viết',
          to: 'posts',
        },

        //ORDER
        {
          aside: { fontIcon: 'bi-chat-left' },
          children: [
            {
              hasBullet: true,
              title: 'Danh sách Đơn hàng',
              to: 'order',
            },
          ],
          icon: '/media/icons/duotune/general/order.svg',
          menu: {
            hasArrow: true,
            menuPlacement: 'right-start',
            menuTrigger: `{default:'click', lg: 'hover'}`,
          },
          title: 'Quản lý Đơn Hàng',
          to: '/order',
        },

        //SYSTEM_MANAGER
        {
          aside: { fontIcon: 'bi-chat-left' },
          children: [
            {
              hasBullet: true,
              title: 'Quản lý SEO',
              to: 'system',
            },
          ],
          icon: '/media/icons/duotune/general/ic_settings.svg',
          menu: {
            hasArrow: true,
            menuPlacement: 'right-start',
            menuTrigger: `{default:'click', lg: 'hover'}`,
          },
          title: 'Quản lý hệ thống',
          to: '/system',
        },
      ],
      menu: { menuPlacement: 'bottom-start', menuTrigger: 'click' },
      title: 'Quản lý menu',
      to: '/posts',
    },
  ];

  function setMenuList(menuList: MenuModel[]) {
    dispatch(menuActions.setMenuList(menuList));
  }

  function setDefaultMenuList() {
    setMenuList(defaultMenuList);
  }

  return { menuList, setDefaultMenuList, setMenuList };
}

export { useMenu };
