export enum UserType {
  customer = 'CUSTOMER',
  driver = 'DRIVER',
  employee = 'EMPLOYEE',
}

export enum UserStatus {
  active = 'ACTIVE',
  blocked = 'BLOCKED',
  deactive = 'DEACTIVE',
  not_verify = 'NOT_VERIFY',
  not_verify_mail = 'NOT_VERIFY_MAIL',
  update_service = 'UPDATE_SERVICE',
  verify = 'VERIFY',
}

export interface UserModel {
  avatar: string;
  country_code: string;
  created_at: string;
  email: string;
  full_name: string;
  id: string;
  password: string;
  phone_number: string;
  role: string;
  status: UserStatus;
  updated_at: string;
  user_name: string;
  user_type: UserType;
}

export interface AuthModel extends UserModel {
  token: string;
}

export interface FormLoginModel {
  email: string;
  password: string;
}
