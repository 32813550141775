import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { QUERIES } from '@/constants/query';
import { useCallApi } from '@/hooks/useCallApi';
import { ID, MutationOptions } from '@/models';
import { FormSystemModel } from '@/modules/system/models/form';
import { fakeNetworkResponse } from '@/utils';

function endpoint(id: ID) {
  return `${id}`;
}

export function useUpdateEmployee(
  options?: MutationOptions<boolean, { form: FormSystemModel; id: ID }>
) {
  const { handleCallApi } = useCallApi();
  const queryClient = useQueryClient();
  const updateMessage = 'Cập nhật bản ghi thành công';

  function handler({ id, form }: { form: FormSystemModel; id: ID }) {
    return handleCallApi(
      async () => {
        await axiosClient.put(endpoint(id), { ...form });
        queryClient.invalidateQueries({ queryKey: [QUERIES.EMPLOYEE_LIST] });
        queryClient.invalidateQueries({ queryKey: [QUERIES.EMPLOYEE_DETAILS, id] });

        return fakeNetworkResponse(true);
      },
      true,
      updateMessage
    );
  }

  return useMutation(handler, options);
}
