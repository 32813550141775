import { rankItem } from '@tanstack/match-sorter-utils';
import { cloneDeep, isArray, isObject } from 'lodash-es';
import moment from 'moment';
import { customAlphabet } from 'nanoid';

import { IAppAxiosError } from '@/common/error';
import { ALPHABET_RANDOM_ID, APP_COLOR } from '@/constants';
import { Color, INetworkResponse, UserStatus } from '@/models';
import { fileUploadModelToString, isFileUploadModel } from '@/utils';

export const isTokenExpired = (appError: IAppAxiosError) => {
  const statusCode = appError.getError().code;
  const message = appError.getMessage();

  return statusCode === '401' && message === 'Token is expired.';
};

export const removeUTF8Chars = (str: string, toLowerCase = true) => {
  // remove accents
  str = toLowerCase ? str.toLowerCase() : str;

  return str
    .normalize('NFD') //remove accents with Normalize, NFD is Normalized Form D
    .replace(/[\u0300-\u036f]/g, '') //range from u0300 within u036f the Unicode encoding
    .replace(/đ/g, 'd') // case 'đ': normalize will not return d ,so have to do it like this
    .replace(/Đ/g, 'D'); //same as above
};

export const getRandomAppColor = (): string => {
  return APP_COLOR[Math.floor(Math.random() * APP_COLOR.length)];
};

export const formatMoney = (number?: number): string => {
  if (!number) {
    return '0 ₫';
  }

  const formatter = new Intl.NumberFormat('vi', { currency: 'VND', style: 'currency' });

  return formatter.format(number);
};

export const fakeNetworkResponse = <T>(data: T): INetworkResponse<T> => {
  return { data };
};

/**
 * It takes an object and returns a new object with all FileUploadModel values converted to strings
 * @param {T} data - T - the data to be prepared
 * @returns A function that takes a generic type T and returns a generic type T.
 */
export const prepareDataToRequest = <T extends object>(data: T): T => {
  if (!isObject(data)) return data;
  const newData = cloneDeep(data);

  for (const key in newData) {
    const value = newData[key];
    if (isFileUploadModel(value)) {
      newData[key] = fileUploadModelToString(value) as unknown as T[Extract<keyof T, string>];
    } else if (isArray(value)) {
      newData[key] = value.map((v) => {
        if (isFileUploadModel(v)) {
          return fileUploadModelToString(v);
        }

        return v;
      }) as unknown as T[Extract<keyof T, string>];
    }
  }

  return newData;
};

export const formatPhoneNumber = (phone: string, prefix = '(+84)') => {
  // remove non-numeric characters
  const cleaned = phone.replace(/\D/g, '');

  // phone number is 10 digits
  const match = cleaned.match(/^(\d{1})(\d{2})(\d{3})(\d{4})$/);
  if (!match) return;

  return `${prefix || match[1]}${match[2]} ${match[3]} ${match[4]}`;
};

/**
 * It takes in a string and a comparison string, and returns a boolean value based on whether the
 * string matches the comparison string
 * @param {string} input - The input string that the user is typing into the search box
 * @param {string} comparison - The string to compare against
 * @returns A function that takes two arguments, input and comparison, and returns a boolean.
 */
export const fuzzyFilter = (input: string, comparison: string) => {
  // Rank the item
  const itemRank = rankItem(comparison, input);

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const getUserStatusColor = (status?: UserStatus) => {
  switch (status) {
    case UserStatus.not_verify:
      return Color.warning;
    case UserStatus.verify:
      return Color.success;
    case UserStatus.not_verify_mail:
      return Color.warning;
    case UserStatus.update_service:
      return Color.warning;
    case UserStatus.deactive:
      return Color.danger;
    case UserStatus.active:
      return Color.success;
    default:
      return Color.dark;
  }
};

export const formatInputMoney = (number: number): string => {
  return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const capitalizeFirstLetter = (text?: string) => {
  return (text || '').charAt(0).toUpperCase() + (text || '').slice(1);
};

export const convertTimeToSeconds = (time: string): number => {
  const minute = Number(time[0] + time[1]);
  const second = Number(time[3] + time[4]);

  const totalSecond = minute * 60 + second;

  return totalSecond;
};

export const convertSecondsToHour = (seconds: number): string => {
  return moment.unix(seconds).utc().format('H [giờ] m [phút]');
};

export const convertStringToSlug = (value: string, oldSlug = false, size = 6): string => {
  //nếu có oldSlug thì value không thay đổi
  if (oldSlug) {
    return value;
  }

  // random id
  const nanoid = customAlphabet(ALPHABET_RANDOM_ID, size);

  //  Chuyển hết sang chữ thường
  value = value.toLowerCase();

  // xóa dấu
  value = value
    .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp

  // Thay ký tự đĐ
  value = value.replace(/[đĐ]/g, 'd');

  // Xóa ký tự đặc biệt
  value = value.replace(/([^0-9a-z-\s])/g, '');

  // Xóa khoảng trắng thay bằng ký tự -
  value = value.replace(/(\s+)/g, '-');

  // Xóa ký tự - liên tiếp
  value = value.replace(/-+/g, '-');

  // xóa phần dư - ở đầu & cuối
  value = value.replace(/^-+|-+$/g, '');

  // return
  return nanoid() ? value + '-' + nanoid() + '.html' : value;
};

export function fetchVideoDuration(videoUrl: string) {
  return videoUrl;
}
