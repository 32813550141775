import { useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/api/axiosClient';
import { useCallApi } from '@/hooks/useCallApi';
import { QueryOptions } from '@/models';
import { LevelModel } from '@/models/level';

function endpoint() {
  return `/course/level/list`;
}

export function useGetLevelList(
  options?: QueryOptions<LevelModel[], unknown, LevelModel[], string[]>
) {
  const { handleCallApi } = useCallApi();

  function handler() {
    return handleCallApi(() => axiosClient.get<LevelModel[]>(endpoint()));
  }

  return useQuery(['course-level-list'], handler, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });
}
