import { ColumnDef, ColumnResizeMode, createColumnHelper } from '@tanstack/react-table';

import { TableActionCellFlatStyle } from '@/components/molecules/TableActionCellFlatStyle';
import { TableHeaderCell } from '@/components/molecules/TableHeaderCell';
import { TableTagCell } from '@/components/molecules/TableTagCell';
import { TableVisibilityColumnCell } from '@/components/molecules/TableVisibilityColumnCell';
import { StudentModel } from '@/modules/student/models';
import { getTimeFormat } from '@/utils';

function getColumnDef(
  columnResizeMode: ColumnResizeMode = 'onChange',
  onClickEdit?: (data: StudentModel) => void
): ColumnDef<StudentModel, any>[] {
  const columnHelper = createColumnHelper<StudentModel>();

  return [
    columnHelper.accessor('full_name', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Tên"
        />
      ),
    }),

    columnHelper.accessor('email', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="email"
        />
      ),
    }),

    columnHelper.accessor('phone', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-50px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="số điện thoại"
        />
      ),
    }),

    columnHelper.accessor('status', {
      cell: ({ row }) => {
        const isActive = row.original.status;

        return (
          <TableTagCell color={isActive === 'ACTIVE' ? 'success' : 'danger'}>
            {isActive}
          </TableTagCell>
        );
      },
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-40px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="trạng thái"
        />
      ),
    }),

    columnHelper.accessor('score', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-30px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="điểm"
        />
      ),
    }),

    columnHelper.accessor('role', {
      cell: ({ row }) => {
        const isActive = row.original.role;

        return (
          <TableTagCell color={isActive === 'MEMBER' ? 'primary' : 'info'}>{isActive}</TableTagCell>
        );
      },
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-40px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="quyền"
        />
      ),
    }),

    columnHelper.accessor('count_courses', {
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-50px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="số khóa học"
        />
      ),
    }),

    columnHelper.accessor('created_at', {
      cell: ({ row }) => getTimeFormat(row.original.created_at),
      enableResizing: false,
      enableSorting: false,
      header: ({ header }) => (
        <TableHeaderCell
          className="w-70px"
          columnResizeMode={columnResizeMode}
          header={header}
          title="Ngày tạo"
        />
      ),
    }),

    columnHelper.accessor((row) => row.id, {
      cell: ({ row }) => (
        <TableActionCellFlatStyle
          data={row.original}
          isShowRemoveButton={false}
          isShowViewButton={false}
          onClickEdit={onClickEdit}
        />
      ),
      enableHiding: false,
      enableResizing: false,
      enableSorting: false,
      header: ({ header, table }) => (
        <TableHeaderCell className="w-35px" columnResizeMode={columnResizeMode} header={header}>
          <TableVisibilityColumnCell
            className="justify-content-end"
            menuClassName="text-primary"
            table={table}
            title="tác vụ"
          />
        </TableHeaderCell>
      ),
      id: 'actions',
    }),
  ];
}

export { getColumnDef };
